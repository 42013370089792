import React from 'react';
import { useState } from 'react';
import './Login.css';
import logo from './Assets/clientHeader.png';
import LoginConnecting from './LoginConnectng';






function Login(prop) {
    const [register, setRegister] = useState(false);

    const handleLogin = (e, name, password) => {
        e.preventDefault();
        prop.login(name, password);
    }

    const handleRegister = (e, name, password, email) => {
        e.preventDefault();
        prop.register(name, password, email);
    }

    if (prop.connecting) {
        return <LoginConnecting connecting={prop.connecting} connected={prop.connected} register={register} loggedIn={prop.loggedIn} />;
    }

    return (
        <div className='wrapper'>
            <img className="logo" src={logo} alt="Logo" style={{ marginBottom: "20px" }} />
            {register ?
                <RegistrationForm handleRegister={handleRegister} setRegister={setRegister} />
                :
                <LoginForm handleLogin={handleLogin} setRegister={setRegister} />
            }
        </div>
    );

    // function RequestPassword() {
    //     return <>
    //         <form className='form'>
    //             <div>
    //                 <label htmlFor='email'>Account</label>
    //                 <input className="email" text="text" placeholder='Account' autoFocus />
    //             </div>
    //             <div>
    //                 <label htmlFor='email'>Email</label>
    //                 <input className="email" type="text" placeholder='you@example.com' />
    //             </div>
    //             <button className="sign" type="submit">Request Code</button>
    //             <button className="register" onClick={(e) => setRegister(false)}>Back</button>
    //         </form>
    //     </>;
    // }

    // function ChangePassword() {
    //     return <>
    //         <form>
    //             <div>
    //                 <label htmlFor='account'>Account</label>
    //                 <input className="account" text="text" placeholder='Account' autoFocus />
    //             </div>
    //             <div>
    //                 <label htmlFor='email'>Email</label>
    //                 <input className="email" type="text" placeholder='you@example.com' />
    //             </div>
    //             <div>
    //                 <label htmlFor='password'>Password</label>
    //                 <input className="password" type="password" placeholder='Password' />
    //             </div>
    //             <div>
    //                 <label htmlFor='password'>Verify Password</label>
    //                 <input className="verify-password" type="password" placeholder='Verify Password' />
    //             </div>
    //             <button className="sign" type="submit">Reset Password</button>
    //             <button className="register" onClick={(e) => setRegister(false)}>Back</button>
    //         </form>
    //     </>;
    // }


}


function LoginForm({ handleLogin, setRegister }) {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    // const inputRef = useRef(null)
    return <>
        <form className='form' onSubmit={(e) => handleLogin(e, name, password)}>
            {/* <h1 className='home-title'>Account Login</h1> */}
            <div>
                <label htmlFor='username'>Account</label>
                <input className="username" type="text" placeholder='Account'
                    onChange={(e) => setName(e.target.value)} value={name} autoFocus />
            </div>
            <div>
                <label htmlFor='password'>Password</label>
                <input className="password" type="password" placeholder='Password'
                    onChange={(e) => setPassword(e.target.value)} value={password} />
            </div>
            <a href="sdsd">Forgot Password</a>
            <button className="sign" type="submit">Log In</button>
        </form>
        <button className='register' onClick={(e) => setRegister(true)}>Create Account</button>
    </>;
}


function RegistrationForm({ handleRegister, setRegister }) {
    const [account, setAccount] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState([]);


    const validAccount = () => {
        return account.length >= 4 && account.length <= 18;
    }

    const passwordsMatch = () => {
        return password === password2;
    }

    const validEmail = () => {
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleRegister(e, account, password, email);
    }

    return <>
        <form onSubmit={(e) => handleSubmit(e)} className='form'>
            <div>
                <label htmlFor='username'>Account</label>
                <input className="username" text="text" placeholder='Account' autoFocus
                    onChange={(e) => setAccount(e.target.value)} value={account}
                />
            </div>
            <div>
                <label htmlFor='email'>Email</label>
                <input className="email" type="text" placeholder='you@example.com'
                    onChange={(e) => setEmail(e.target.value)} value={email}
                />
            </div>
            <div>
                <label htmlFor='password'>Password</label>
                <input className="password" type="password" placeholder='Password'
                    onChange={(e) => setPassword(e.target.value)} value={password}
                />
            </div>
            <div>
                <label htmlFor='password'>Verify Password</label>
                <input className="verify-password" type="password" placeholder='Verify Password'
                    onChange={(e) => setPassword2(e.target.value)} value={password2} />
            </div>
            <button className="sign" type="submit">Create Account</button>
            <button className="register" onClick={(e) => setRegister(false)}>Back</button>
        </form>
    </>;
}


// function LoginForm({ handleSubmit, name, password }) {
//     return (
//         <form onSubmit={handleSubmit}>
//             {/* <h1>Account Login</h1> */}
//             <div>
//                 <label htmlFor='username'>Account</label>
//                 <input className="username" type="text" placeholder='Account'
//                     onChange={(e) => setName(e.target.value)} value={name} />
//             </div>
//             <div>
//                 <label htmlFor='password'>Password</label>
//                 <input className="password" type="password" placeholder='Password'
//                     onChange={(e) => setPassword(e.target.value)} value={password} />
//             </div>
//             <a href="sdsd">Forgot Password</a>
//             <button className="sign" type="submit">Log In</button>
//             <button to="/sign-up" className='register' onClick={(e) => setRegister(true)}>Create Account</button>
//         </form>
//     )
// }

export default Login;