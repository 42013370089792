import React from "react";
import './StatIncrement.css';

function StatIncrement({ name, value, increase, increaseStat, decreaseStat }) {
    return (
        <div className="stat-wrapper">

            <span className="stat-name">{name}</span>
            <span className="stat-value">{value + increase}</span>
            <span className="stat-mod">
                {increase > 0 ? '(+' + increase + ')' : ''}
            </span>

            <div className="train-buttons">
                <button onClick={e => { e.preventDefault(); decreaseStat(name) }}>-</button>
                <button onClick={e => { e.preventDefault(); increaseStat(name) }}>+</button>
            </div>
        </div>
    );
}

export default StatIncrement;