import React, { useState, useContext } from 'react';
import CharacterPanel from './Components/CharacterPanel';
import './main.css';
import UserList from './UserList';
import MapGrid from './MapGrid';
import RoomInfo from './RoomInfo';
import Trainer from './Components/trainer/Trainer';
import ChatInput from './Components/ChatInput';
import ChatConsole from './Components/ChatConsole';
import StaminaBar from './Components/StaminaBar';
import HealthBar from './Components/HealthBar';
import { AppState } from "./Home";
import TrainStat from './Components/modals/TrainStat';
import Shop from './Components/Shop/Shop';
import Hotkeys from './Components/ActionBar/Hotkeys';
import HotkeyEdit from './Components/ActionBar/HotkeyEdit';
import PlayerActions from './Components/ActionBar/PlayerActions';
import Inventory from './Components/Inventory/Inventory';

function Main({ messages, sendMessage, roomListPlayers, roomListMobs, mapInfo, position, playerStats, shopList, shopInfo, gold, inventory, inventoryInfo, equipment }) {
    const context = useContext(AppState);
    const { roomInfo, health, mana, stamina, statPoints, buffs } = context;
    const [showModal, setShowModal] = useState(null);
    const [actions, setActions] = useState([
        [
            { key: 'F1', name: "Attack", cmd: "/a" },
            { key: 'F2', name: "Stop Attack", cmd: "/stop" },
            { key: 'F3', name: "Edit Hotkeys", cmd: "/hotkey" },
            { key: 'F4', name: "Help", cmd: "/help" },
            { key: 'F5', name: "", cmd: "" },
            { key: 'F6', name: "", cmd: "" },
            { key: 'F7', name: "", cmd: "" },
            { key: 'F8', name: "", cmd: "" },
            { key: 'F9', name: "", cmd: "" },
        ],
        [
            { key: 'F1', name: "", cmd: "" },
            { key: 'F2', name: "", cmd: "" },
            { key: 'F3', name: "", cmd: "" },
            { key: 'F4', name: "", cmd: "" },
            { key: 'F5', name: "", cmd: "" },
            { key: 'F6', name: "", cmd: "" },
            { key: 'F7', name: "", cmd: "" },
            { key: 'F8', name: "", cmd: "" },
            { key: 'F9', name: "", cmd: "" },
        ],
        [
            { key: 'F1', name: "", cmd: "" },
            { key: 'F2', name: "", cmd: "" },
            { key: 'F3', name: "", cmd: "" },
            { key: 'F4', name: "", cmd: "" },
            { key: 'F5', name: "", cmd: "" },
            { key: 'F6', name: "", cmd: "" },
            { key: 'F7', name: "", cmd: "" },
            { key: 'F8', name: "", cmd: "" },
            { key: 'F9', name: "", cmd: "" },
        ],
        [
            { key: 'F1', name: "", cmd: "" },
            { key: 'F2', name: "", cmd: "" },
            { key: 'F3', name: "", cmd: "" },
            { key: 'F4', name: "", cmd: "" },
            { key: 'F5', name: "", cmd: "" },
            { key: 'F6', name: "", cmd: "" },
            { key: 'F7', name: "", cmd: "" },
            { key: 'F8', name: "", cmd: "" },
            { key: 'F9', name: "", cmd: "" },
        ],
    ]);



    function displayModal(type) {
        setShowModal(type);
    }

    return (
        <>
            {showModal === 'trainer' && (<Trainer displayModal={displayModal} />)}
            {showModal === 'trainstats' && (<TrainStat displayModal={displayModal} playerStats={playerStats} statPoints={statPoints} sendMessage={sendMessage} />)}
            {showModal === 'shop' && <Shop displayModal={displayModal} shopList={shopList} shopInfo={shopInfo} sendMessage={sendMessage} gold={gold} />}
            {showModal === 'hotkey' && <HotkeyEdit displayModal={displayModal} actions={actions} setActions={setActions} />}
            {showModal === 'inventory' && <Inventory displayModal={displayModal} inventory={inventory} equipment={equipment} sendMessage={sendMessage} itemInfo={inventoryInfo} />}

            <div className='container' >
                <div className='left main-border'>
                    <CharacterPanel playerStats={playerStats} />
                    <HealthBar cur={health.cur} max={health.max} name="Health" />
                    <HealthBar cur={mana.cur} max={mana.max} name="Mana" />
                    <UserList displayModal={displayModal} />
                    <StaminaBar stamina={stamina} />
                    <PlayerActions displayModal={displayModal} sendMessage={sendMessage} />
                    <Hotkeys displayModal={displayModal} actions={actions} sendMessage={sendMessage} />
                </div>
                <div className='room-grid'>
                    <div className='room-info-area main-border'>
                        <MapGrid mapInfo={mapInfo} position={position} area={roomInfo.area} />
                        <RoomInfo />
                    </div>
                    {/* {buffs.length > 0 && <BuffPanel buffs={buffs} />} */}
                    <ChatConsole messages={messages} buffs={buffs} />
                    <ChatInput sendMessage={sendMessage} />
                </div>
            </div>
        </>
    );
}

export default Main;