import React, { useEffect, useState, useRef, useCallback } from "react";
import "./ChatInput.css";
import ChatSelector from "./ChatSelector";


const directionMap = {
    'ArrowUp': 'north',
    'KeyW': 'north',
    'ArrowDown': 'south',
    'KeyS': 'south',
    'ArrowRight': 'east',
    'KeyD': 'east',
    'ArrowLeft': 'west',
    'KeyA': 'west',
    'PageUp': 'up',
    'KeyR': 'up',
    'PageDown': 'down',
    'KeyF': 'down'
};


function ChatInput({ sendMessage }) {
    const [input, setInput] = useState('');
    const [moving, setMoving] = useState(false);
    const [shiftDown, setShiftDown] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [historyPosition, setHistoryPosition] = useState(0);
    const [channel, setChannel] = useState(0);

    const inputRef = useRef(null);

    function hasFocus() {
        return document.activeElement === inputRef.current;
    }
    function setFocus() {
        inputRef.current.focus();
    }

    function removeFocus() {
        inputRef.current.blur();
    }

    const handleKeyPress = useCallback((event) => {
        const { code } = event;

        if (hasFocus()) {
            if (code === 'Escape') {
                removeFocus();
            }
        } else if (code === 'KeyC') {
            setFocus();
            event.preventDefault();
        } else {
            const direction = directionMap[code];
            if (direction) {
                startMoving(direction);
            }
        }
    }, [moving]);

    const handleKeyRelease = useCallback((event) => {
        if (hasFocus()) {
            return;
        }
        if (directionMap[event.code]) {
            stopMoving();
        }
    }, [moving]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        window.addEventListener('keyup', handleKeyRelease)

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
            window.removeEventListener('keyup', handleKeyRelease);
        };
    }, [handleKeyPress, handleKeyRelease]);

    const handleKeyDown = (event) => {
        if (shiftDown) {
            handleHistory(event);
            return;
        }

        if (!moving && event.key === 'Shift') {
            setShiftDown(true);
            return;
        }

        if (!moving && input === '') {
            switch (event.key) {
                case 'ArrowUp':
                    startMoving('north');
                    break;
                case 'ArrowDown':
                    startMoving('south');
                    break;
                case 'ArrowLeft':
                    startMoving('west');
                    break;
                case 'ArrowRight':
                    startMoving('east');
                    break;
                case 'PageUp':
                    startMoving('up');
                    break;
                case 'PageDown':
                    startMoving('down');
                    break;
                default:
                    break;
            }
        }
    }

    const handleKeyUp = (event) => {
        if (event.key === 'Shift') {
            setHistoryPosition(0);
            setShiftDown(false);
        }

        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowDown':
            case 'ArrowLeft':
            case 'ArrowRight':
            case 'PageUp':
            case 'PageDown':
                stopMoving();
                break;
            default:
                break;
        }

    }

    const handleHistory = (event) => {
        if (historyPosition < chatHistory.length) {
            if (event.key === 'ArrowUp') {
                const pos = historyPosition + 1;
                setInput(chatHistory[chatHistory.length - pos]);
                setHistoryPosition(pos);
            }
            if (event.key === 'ArrowDown' && historyPosition > 0) {
                const pos = historyPosition - 1;
                setInput(chatHistory[chatHistory.length - historyPosition]);
                setHistoryPosition(pos);
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const msg = input.trim();
        if (msg.length > 0) {
            if(msg.startsWith('/')){
                sendMessage('600' + msg);
            } else {
                switch (channel) {
                    case 0:
                        sendMessage('600' + msg);
                        break;
                    case 1:
                        sendMessage('600/g ' + msg);
                        break;
                    case 2:
                        sendMessage('600/p ' + msg);
                        break
                    case 3:
                        break;
                }
            }
            if (chatHistory[chatHistory.length - 1] !== msg) {
                setChatHistory(prev => [...prev, msg]);
            }
            setInput('');
        }
    }

    function startMoving(direction) {
        if (!moving) {
            setMoving(true);
            move(direction);
        }
    }

    function stopMoving() {
        move('stop');
        setMoving(false);
    }

    const move = (direction) => {
        sendMessage('310' + direction);
    }

    return (
        <>
            <form className="input-form" onSubmit={handleSubmit}>
                <ChatSelector setChannel={setChannel} />
                <input
                    ref={inputRef}
                    className="chat-input"
                    type="text"
                    onChange={(e) => setInput(e.target.value)}
                    value={input}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                />
            </form>
        </>
    )
}

export default ChatInput;