import React, { useContext, useEffect, useState } from 'react';
import { AppState } from "./Home";
import './roominfo.css';

function RoomInfo() {
    const context = useContext(AppState);
    const { roomInfo } = context;
    const [directions, setDirections] = useState({ north: false, south: false, east: false, west: false, up: false, down: false });
    const [exits, setExits] = useState([]);
    
    useEffect(() => {
        const parsedDirections = parse(roomInfo.exits);
        const filterExits = Object.entries(parsedDirections)
            .filter(([key, val]) => val === true)
            .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1));

        const formatExits = [];

        for (let i = 0; i < filterExits.length; i++) {
            if (i > 0) {
                formatExits.push(i === filterExits.length - 1 ? ' and ' : ', ');
            }
            formatExits.push(<span key={i} className='active'>{filterExits[i]}</span>);
        }

        setExits(formatExits);
        setDirections(parsedDirections);
    }, [roomInfo]);


    return (
        <>
            <section className='room-panel'>
                <header className='room-title'>{roomInfo.title}</header>
                <p className='room-description'>{roomInfo.desc}</p>
                <p className='room-exit-text'>Exits: {(exits)}.</p>
                <div className='room-exits'>
                    <span className={'material-symbols-outlined exit-north ' + (directions.north ? 'active' : 'inactive')}>
                        keyboard_arrow_up
                    </span>
                    <span className={'material-symbols-outlined exit-west ' + (directions.west ? 'active' : 'inactive')}>
                        keyboard_arrow_left
                    </span>
                    <span className={'material-symbols-outlined exit-east ' + (directions.east ? 'active' : 'inactive')}>
                        keyboard_arrow_right
                    </span>
                    <span className={'material-symbols-outlined exit-south ' + (directions.south ? 'active' : 'inactive')}>
                        keyboard_arrow_down
                    </span>
                    <div className='exit-mid'>
                        <div className='holder'>
                            <span className={'material-symbols-outlined exit-up ' + (directions.up ? 'active' : 'inactive')}>
                                north
                            </span>
                            <span className={'material-symbols-outlined exit-down ' + (directions.down ? 'active' : 'inactive')}>
                                south
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}



function parse(exits) {
    const direction = exits.split("/");
    let north = false;
    let south = false;
    let east = false;
    let west = false;
    let up = false;
    let down = false;

    for (let i = 0; i < direction.length; i++) {
        if (direction[i] === 'n') {
            north = true;
        }
        if (direction[i] === "e") {
            east = true;
        }
        if (direction[i] === "w") {
            west = true;
        }
        if (direction[i] === "s") {
            south = true;
        }
        if (direction[i] === "d") {
            down = true;
        }
        if (direction[i] === "u") {
            up = true;
        }
    }
    return { north: north, south: south, east: east, west: west, up: up, down: down };
}

export default RoomInfo;