import React from "react";
import './Buffs.css';
import BuffElement from "./BuffElement";

function BuffPanel({ buffs }) {

    return (
        <div className="buffPanel">
            {buffs.map((b, i) => <BuffElement key={i} buff={b}/>)}
        </div>
    );

}

export default BuffPanel;