import React, { useState } from "react";
import './Equipment.css';

const slotType = [
    'Neck',
    'Head',
    'Hands',
    'Weapon',
    'Body',
    'Offhand',
    'Wrist 1',
    'Legs',
    'Wrist 2',
    'Ring 1',
    'Feet',
    'Ring 2'
]

function Equipment({ equipment, getEquipInfo, unequip, handleDrop }) {

    function findEquipment(slot) {
        const equip = equipment.filter(e => e.slot === slot.toLowerCase());
        return equip[0];
    }

    return (
        <div className="equipment-slots">
            {slotType.map((s) => <EquipmentSlot key={s} slotName={s} item={findEquipment(s)} getEquipInfo={getEquipInfo} unequip={unequip} handleDrop={handleDrop} />)}
        </div>
    );

}

function EquipmentSlot({ slotName, item, getEquipInfo, unequip, handleDrop }) {
    const [showRemove, setShowRemove] = useState(false);


    const drag = (slotName) => {
        switch (slotName.toLowerCase()) {
            case "weapon":
                return "m ";
            case "offhand":
                return "o ";
            case "wrist 1":
                return "wrist1";
            case "wrist 2":
                return "wrist2"
            case "ring 1":
                return "ring1";
            case "ring 2":
                return "ring2";
            default:
                break;
        }
        return "";
    }

    const unequipItem = (e, slotName) => {
        e.preventDefault();
        e.stopPropagation();
        unequip(slotName);
    }

    return (
        <div className="slot-container">
            <div className="slot"
                onClick={e => getEquipInfo(e, slotName)}
                onContextMenuCapture={e => unequipItem(e, slotName)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e, drag(slotName))}
                onMouseEnter={() => setShowRemove(true)}
                onMouseLeave={() => setShowRemove(false)}
            >
                {item ?
                    <div>
                        <img src={require('../../Assets/gfx/' + item.picId + '.png')} alt='' style={{ transform: slotName === 'Wrist 2' && 'scaleX(-1)' }} />
                        {showRemove &&
                        <div className="unequip-icon" onClick={e => unequipItem(e, slotName)}><p className="uneq">&times;</p></div>
                        }
                        </div>
                    :
                    slotName
                }
            </div>
            <p className="equipment-name" style={{ color: item?.colour }}>{item?.name}</p>
        </div>
    );
}

export default Equipment;