import React, { useState, useEffect } from "react";
import './BuffElement.css';


function BuffElement({ buff }) {
    const [isRunning, setIsRunning] = useState(true);
    const [time, setTime] = useState(0);
    const [displayToolTip, setDisplayToolTip] = useState(null);
    useEffect(() => {
        let intervalId;

        if (isRunning) {
            intervalId = setInterval(() => {
                if (buff.duration > 0) {
                    buff.duration = buff.duration - 1000;
                    setTime(buff.duration);
                }
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [isRunning]);


    function ToolTip() {
        return (
            // style={{ left: displayToolTip.x + 'px', top: displayToolTip.y + 'px' }}
            <div className="tooltip" style={{ left: displayToolTip.x + 'px', top: displayToolTip.y + 'px' }}>
                <h3>{buff.name}</h3>
                {buff.description.split('<br>').map(text => <p>{text}</p>)}
                <span>[{msToTime(time)}]</span>
            </div>
        )
    }

    return (


        <div className="buff">
            <div
                onMouseMove={(e) => {
                    if (!displayToolTip) {
                        setDisplayToolTip({ x: e.clientX, y: e.clientY })
                    }
                }}
                onMouseLeave={(e) => setDisplayToolTip(false)}>
                <img className='buff-pic' src={require('../../Assets/gfx/' + buff.picId + '.png')}
                alt=""
                />
                </div>
                <p>{buff.shortName}</p>
                <span className="buff-time">[{msToTime(time)}]</span>
                {displayToolTip && <ToolTip />}
            </div>

    );

}

function msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60);

    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return minutes + "m" + seconds + "s";
}

export default BuffElement;