import React, { useRef, useEffect } from "react";
import "./ChatConsole.css";
import BuffPanel from "./buffs/BuffPanel";

function ChatConsole({ messages, buffs}) {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    }, [messages]);

    return (
        <ul ref={ref} className='scroll-chat'>
                                {buffs.length > 0 && <BuffPanel buffs={buffs} />}

            {messages.map((message, index) => (<p className="messages" key={index}>{message}</p>))}
        </ul>
    )
}

export default ChatConsole;