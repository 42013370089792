import React, { useContext } from 'react';
import { AppState } from "./Home";

import './userlist.css';
import UserListElement from './UserListElement';

function UserList({displayModal}) {
    const context = useContext(AppState);
    const { roomListMobs, roomListPlayers, sendMessage } = context;
    return (
        <div className='userlist-panel'>
            <div className="userlist">
                {roomListMobs.map((details, index) => (<UserListElement key={details.id}  pos={index} details={details} sendMessage={sendMessage} displayModal={displayModal}/>))}
                {roomListPlayers.map((details, index) => (<UserListElement  key={details.id} pos={roomListMobs.length + index} sendMessage={sendMessage} details={details} />))}
            </div>
        </div>
    );
}

export default UserList;