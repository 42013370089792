import React, { useState, useEffect } from "react";
import StatIncrement from "../modals/StatIncrement";
import './CreateCharacter.css';
import PortraitSelector from "./PortraitSelector";

function CreateCharacter({ createInfo, sendMessage, toggleCreate }) {
    const [showPortraitSelector, setShowPortraitSelector] = new useState(false);
    const [selectedImage, setSelectedImage] = useState(-1);
    const [characterName, setCharacterName] = useState('');
    const [characterGender, setCharacterGender] = useState(0);
    const [characterClass, setCharacterClass] = useState('');
    const [characterRace, setCharacterRace] = useState('');

    const intitalValues = { strength: 0, agility: 0, toughness: 0, intelligence: 0, wisdom: 0, charisma: 0 };
    const [trainStats, setTrainStats] = useState(intitalValues);
    const [review, setReview] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {

        setCharacterRace(createInfo.races[0]);
        setCharacterClass(createInfo.classList[0]);
        setCharacterGender(0);

    }, [createInfo]);



    const increaseStat = (name) => {
        const totalTrainStats = Object.values(trainStats).reduce((a, b) => a + b, 0);
        if (totalTrainStats < createInfo.statPoints) {
            setTrainStats(prevStats => ({ ...prevStats, [name.toLowerCase()]: prevStats[name.toLowerCase()] + 1 }));
        }
    }

    const decreaseStat = (name) => {
        if (trainStats[name.toLowerCase()] > 0) {
            setTrainStats(prevStats => ({ ...prevStats, [name.toLowerCase()]: prevStats[name.toLowerCase()] - 1 }));
        }
    }

    const createCharacter = (e) => {
        e.preventDefault();
        console.log(selectedImage, characterName, characterGender, characterClass, characterRace, trainStats);
        const errors = [];
        if (selectedImage < 0) {
            errors.push('Select a portrait');
        }
        if (characterName.length === 0 || characterName.length > 20) {
            errors.push('Invalid character name');
        }
        if (characterClass === null) {
            errors.push('Choose a class')
        }
        if (characterRace === null) {
            errors.push('Choose a race')
        }
        if (Object.values(trainStats).reduce((a, b) => a + b, 0) < createInfo.statPoints) {
            errors.push('You have stat points to use')
        }
        if (errors.length === 0) {
            setErrorMessages([]);
            setReview(true);
        } else {
            setErrorMessages(errors);
        }
    }

    function updateRaceInfo(value) {
        const selectedRace = createInfo.races.find((race) => race.name === value);
        if (selectedRace) {
            setCharacterRace(selectedRace);
        } else {
            setCharacterRace(createInfo.races[0]);
        }
        setTrainStats(intitalValues);
    }

    function updateSelectedClass(value) {
        const selectedClass = createInfo.classList.find((c) => c.name === value);
        if (selectedClass) {
            setCharacterClass(selectedClass);
        } else {
            setCharacterClass(createInfo.classList[0]);
        }
    }

    function updateGender(value) {
        value === "male" ? setCharacterGender(0) : setCharacterGender(1);
    }

    function createCharacterRequest() {
        sendMessage('003' + characterName + "/" + characterGender + "/" + characterClass.name + "/" +characterRace.name + "/"
            + trainStats.strength + "/" + trainStats.agility + "/" + trainStats.toughness + "/" + trainStats.intelligence + "/" + trainStats.wisdom + "/" + trainStats.charisma + "/" + selectedImage
        )
        toggleCreate();
    }

    function ReviewCharacter() {
        return (
            <>
                <div className="port-content">
                    <h1>Review Character</h1>
                    <div>
                        Name: {characterName}<br />
                        Race: {characterRace.name}<br />
                        Class: {characterClass.name}<br />
                    </div>
                </div>
                <button className="sign" onClick={createCharacterRequest}>Create</button>
            </>

        )
    }

    function DisplayErrors() {
        return (
            <div className="error-content">
                <h1>Error</h1>
                <div>
                    {errorMessages.map((e,i)=> <p key={i}>{e}</p>)}
                </div>
            </div>
        );

    }

    return (
        <>
            {review && <ReviewCharacter />}
            {errorMessages.length > 0 && <DisplayErrors/>}
            {showPortraitSelector && <PortraitSelector selectedImage={selectedImage} setSelectedImage={setSelectedImage} setShowPortraitSelector={setShowPortraitSelector} />}
            {!review &&
                <form className='form' onSubmit={(e) => createCharacter(e)}>
                    <div className="create-form">
                        <div>
                            <label htmlFor="portrait">Portrait</label>
                            <div className="cportrait">

                                <img className="" src={require('../../Assets/gfx/' + ((selectedImage > 0) ? selectedImage : '588') + '.png')} alt="GI"/>
                                <button className="register" onClick={(e) => {
                                    e.preventDefault();
                                    setShowPortraitSelector(true)
                                }
                                }>Select a portrait</button>
                            </div>
                        </div>
                        <div>

                        </div>

                        <div>
                            <label htmlFor="name">Character Name</label>
                            <input className="name" placeholder="Name"
                                onChange={(e) => setCharacterName(e.currentTarget.value)}
                                value={characterName}
                                autoFocus />
                        </div>

                        <div>
                            <label htmlFor="gender">Gender</label>
                            <select className="gender" placeholder="Gender" required
                                onChange={e => updateGender(e.currentTarget.value)}>
                                <option value={0}>Male</option>
                                <option value={1}>Female</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="clazz">Class</label>
                            <select className="clazz"
                                onChange={e => updateSelectedClass(e.currentTarget.value)}>
                                {createInfo.classList.map((e) => <option key={e.name} value={e.name}>{e.name}</option>)}
                            </select>
                            <ClassInformation classInfo={characterClass.description} />
                        </div>
                        <div>
                            <label htmlFor="race">Race</label>
                            <select className="race"
                                onChange={e => updateRaceInfo(e.currentTarget.value)}
                            >
                                {createInfo.races.map(e => <option key={e.name} value={e.name}>{e.name}</option>)}
                            </select>
                            <RaceInformation raceInfo={characterRace.raceAttributes} />
                        </div>
                    </div>

                    <div className="tmodal-body">
                        <div className="stat-points">
                            <p className="spend">Stat Points (<span className="points">{createInfo.statPoints - Object.values(trainStats).reduce((a, b) => a + b, 0)}</span>)</p>
                        </div>
                        <StatIncrement name={'Strength'} value={characterRace.str} increase={trainStats.strength} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                        <StatIncrement name={'Agility'} value={characterRace.agi} increase={trainStats.agility} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                        <StatIncrement name={'Toughness'} value={characterRace.tog} increase={trainStats.toughness} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                        <StatIncrement name={'Wisdom'} value={characterRace.wis} increase={trainStats.wisdom} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                        <StatIncrement name={'Intelligence'} value={characterRace.intel} increase={trainStats.intelligence} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                        <StatIncrement name={'Charisma'} value={characterRace.chr} increase={trainStats.charisma} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                    </div>

                    <div className="create-actions">
                        <button className='sign'>Create Character</button>
                    </div>
                </form >
            }
        </>

    );

}

function RaceInformation({ raceInfo }) {
    return (
        <section className="info-section">
            <h3>Race Attributes</h3>
            <div className="info-text">
                {raceInfo && raceInfo.map((e, index) => <p key={index}>{e}</p>)}
            </div>

        </section>
    )
}
function ClassInformation({ classInfo }) {
    return (
        <section className="info-section">
            <h3>Class Information</h3>
            <div className="info-text">
                <p>{classInfo}</p>
            </div>
        </section>
    )
}

export default CreateCharacter;