import React, { useContext, useState } from "react";
import { AppState } from "../../Home";

import './Trainer.css';

function Trainer({ displayModal }) {
    const context = useContext(AppState);
    const { trainer, abilityInfo, sendMessage } = context;
    const [selectedAbility, setSelectedAbility] = useState('');
    const [hideUnavailable, setHideUnavailable] = useState(false);

    function learnAbility(id, ability) {
        if (ability) {
            sendMessage('316' + id + ";learn " + ability);
            sendMessage("316" + id + ";info " + ability);
        }
    }

    function selectAbility(id, ability) {
        setSelectedAbility(ability);
        sendMessage("316" + id + ";info " + ability);

    }

    function hide() {
        setHideUnavailable(!hideUnavailable);
    }



    return (
        <div className="modal" style={{ display: "block" }}>
            <div className="modal-content">
                <div className="tmodal-header">
                    <span className="close" onClick={displayModal}>&times;</span>
                    <h2>Learn</h2>
                </div>

                <div className="modal-body" style={{ width: '100%', padding: '0px 4px' }}>

                    <div className="ability-container">
                        <div>
                            <div className="ability-list">
                                {hideUnavailable ?
                                    trainer.abilities && trainer.abilities.filter(a => a.b === false).forEach(a => {
                                        <Ability key={a.name} id={trainer.id} ability={a} selectAbility={selectAbility} selectedAbility={selectedAbility} />
                                    })
                                    :
                                    trainer.abilities && trainer.abilities.map((a) =>
                                        <Ability key={a.name} id={trainer.id} ability={a} selectAbility={selectAbility} selectedAbility={selectedAbility} />
                                    )
                                }
                            </div>
                            <select className="filter">
                                <option value="someOption">Some option</option>
                                <option value="otherOption">Other option</option>
                            </select>
                            <label>
                                <input type="checkbox" onClick={e => hide()} />
                                Hide Unavailable
                            </label>
                        </div>

                        <div className="ability-info">
                            <p className="ability-points">Ability Points (<span className="ability-points-amount">{trainer.points}</span>)</p>

                            <div className="info-header">
                                <p>Information</p>
                            </div>
                            {abilityInfo.name &&
                                <div className="info-desc">
                                    <div className="info-info">
                                        <p className="name">Name <span className="value">{abilityInfo.name}</span></p>
                                        <p className="name">Type <span className="value">{abilityInfo.type}</span></p>
                                        <p className="name">Type2 <span className="value">{abilityInfo.type2}</span></p>
                                        {abilityInfo.type === "SPELL" ?
                                            <>
                                                <p className="name">Mana Cost <span className="value">{abilityInfo.castTime}</span></p>
                                                <p className="name">Cast Time <span className="value">{(abilityInfo.cooldown / 1000)}s</span></p>
                                                <span></span>
                                                <p className="name">Level <span className="value">{abilityInfo.level}</span></p>
                                                <p className="name">Cooldown <span className="value">{(abilityInfo.reuse / 1000)}s</span></p>
                                            </>
                                            :
                                            <>
                                                <p className="name">Recovery Time <span className="value">{(abilityInfo.castTime) / 1000}s</span></p>
                                                <p className="name">Cooldown <span className="value">{(abilityInfo.cooldown / 1000)}s</span></p>
                                                <p></p>
                                                <p className="name">Level <span className="value">{abilityInfo.level}</span></p>

                                            </>
                                        }
                                        {/* <p className="name">Level <span className="value">{abilityInfo.level}</span></p> */}
                                    </div>
                                    <p className="description">{abilityInfo.desc}</p>
                                    <div className="stats">
                                        <Requirements reqs={abilityInfo.requirements} />
                                        <Modifiers mods={abilityInfo.modifiers} />
                                    </div>
                                </div>}
                            <div className="bottom">
                                <button className="ts-button" onClick={e => learnAbility(trainer.id, abilityInfo.name)}>Learn</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="ts-close" onClick={displayModal} >Close</button>
                </div>
            </div>
        </div>
    );
}

function Attribute({ name, value }) {
    return (
        <div className="attrib">
            <p className="attrib-name">{name}</p>
            <p className="attrib-value">{value}</p>
        </div>);
}

function Requirements({ reqs }) {
    return (
        <>
            <div className="requirements">
                <h3>Requirements</h3>
                {reqs.map((r, i) => <li key={i} className={r.meetRequirement === 'true' ? 'green' : 'red'}><Attribute name={r.name} value={r.val} /></li>)}
            </div>
        </>
    );
}
function Modifiers({ mods }) {
    return (
        <>
            <div className="modifiers">
                <h3>Modifiers</h3>
                {mods.map((r, i) => <li key={i}><Attribute name={r.name} value={r.val} /></li>)}
            </div>
        </>
    );
}

function Ability({ id, ability, selectAbility, selectedAbility }) {

    return (
        <>
            <div
                className={"ability" + (ability.b !== true ? " req-not-met" : "") + (ability.name === selectedAbility ? " selected" : "")}
                onClick={(e) => selectAbility(id, ability.name)}
            >
                <img className='image' src={require('../../Assets/gfx/' + ability.picId + '.png')} alt=""/>
                <div className={'ability-text'}>
                    <p className={"ability-name" + (ability.b !== true ? " bw" : '')}>{ability.name}</p>
                    <span className="next-level">{ability.level}/{ability.cap}</span>
                </div>
                {/* {ability.isSpell && "spell"} */}
            </div>
        </>)
}

export default Trainer;


// for (let i = 1; i < info.length; i++) {
//     const ability = info[i].split(';');
//     const picId = parseInt(ability[0]);
//     const isSpell = ability[1];
//     const name = ability[2];
//     const level = parseInt(ability[3]);
//     const cap = parseInt(ability[4]);
//     const type = ability[5];
//     const b = ability[6];
//     abilities.push({picId: picId, isSpell: isSpell, name: name, level: level, cap: cap, type: type, b: b});
// }

// const trainer = {
//     id: id[0],
//     points: id[1],
//     abilities: abilities,
// }