import React from 'react';
import './Login.css';
import logo from './Assets/clientHeader.png';
import { Link } from 'react-router-dom';
function Register () {
    return (
        <>
            <div className='wrapper'>
                <img src={logo} alt="Logo" />
                <form>
                    <h1>Create Account</h1>
                    <div>
                        <label htmlFor='username'>Account</label>
                        <input className="username" text="text" placeholder='Account' />
                    </div>
                    <div>
                        <label htmlFor='email'>Email</label>
                        <input className="email" type="text" placeholder='you@example.com' />
                    </div>
                    <div>
                        <label htmlFor='password'>Password</label>
                        <input className="password" type="password" placeholder='Password' />
                    </div>
                    <div>
                        <label htmlFor='password'>Verify Password</label>
                        <input className="verify-password" type="password" placeholder='Verify Password' />
                    </div>
                    <button className="sign" type="submit">Create Account</button>
                    <button as={Link} to="/" className="register" type="submit">Back</button>
                </form>
            </div>
        </>
    );
}

export default Register;