import React, { useState } from 'react';
import './CharacterInfo.css'

function CharacterList({ characterList, onClick }) {
    const [selectCharacter, setSelectCharacter] = useState("");

    return (
        <>
            <CharacterInfoList
                characterList={characterList}
                onClick={onClick}
                selectCharacter={selectCharacter}
                setSelectCharacter={setSelectCharacter}
            />
        </>
    );
}
function CharacterInfoList({ characterList, onClick, selectCharacter, setSelectCharacter }) {
    return (
        <>
            <div className='wrap' >
                <table className='fade-in'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Character</th>
                            <th>Level</th>
                            <th>Class</th>
                            <th>Race</th>
                            <th></th>
                        </tr>
                    </thead>
                    <div>

                    </div>
                    <tbody className='table-body'>
                        {characterList.map(
                            (character) => (<TableRow key={character.id} character={character} onClick={onClick} selectCharacter={selectCharacter} setSelectCharacter={setSelectCharacter} />)
                        )}
                    </tbody>
                </table>
            </div>

            <button className='sign' disabled={(selectCharacter === '')} onClick={e => onClick("002" + selectCharacter)}>
                {selectCharacter === '' ? "Select Character" :
                    "Play " + selectCharacter
                }
            </button>
        </>
    )
}

function TableRow({ character, onClick, selectCharacter, setSelectCharacter }) {
    return (
        <tr style={{ background: character.name === selectCharacter ? '#202065' : '' }} onClick={(e) => setSelectCharacter(character.name)}>
            <td className='port-col'>
                <div className='account-portrait'>
                    <img src={require('./Assets/gfx/' + character.picId + '.png')} alt=''/>
                </div>
            </td>
            <td>{character.name}</td>
            <td>{character.level}</td>
            <td>{character.clazz}</td>
            <td>{character.race}</td>
            <td>
                <div className='play'>
                    <span class="material-symbols-outlined" onClick={e => onClick("002" + character.name)}>
                        play_circle
                    </span>
                </div>
            </td>
        </tr>
    );
}


export default CharacterList;