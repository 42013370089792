import React, { useContext } from 'react';
import { AppState } from "../Home";
import Stat from './Stat';
import Info from './Info';
import Bar from './ProgressBar';
import '../main.css';
import './CharacterPanel.css';
function CharacterPanel({ playerStats }) {
    const context = useContext(AppState);
    const { playerInfo, experience, gold } = context;
    const formatGold = gold.toLocaleString();

    return (
        <>
            <section className="bah" >
                <header className='room-title'>Character Information</header>
                <section className="character-section">
                    <img className='image' src={require('../Assets/gfx/' + playerInfo.picId + '.png')} alt=''/>
                    <div className='character-info'>
                        <Info name="Name" value={playerInfo.name} />
                        <Info name="Level" value={playerStats.level} />
                        <Info name="Class" value={playerInfo.clazz} />
                        <Info name="Race" value={playerInfo.race} />
                        <Bar experience={experience} />
                    </div>
                </section>
                <section className='character-stats'>
                    <Stat name="Strength" base={playerStats.str} mod={playerStats.mstr} />
                    <Stat name="Agility" base={playerStats.agi} mod={playerStats.magi} />
                    <Stat name="Toughness" base={playerStats.tog} mod={playerStats.mtog} />
                    <Stat name="Armour" base={playerStats.armour} mod={0} />
                    <Stat name="Wisdom" base={playerStats.wis} mod={playerStats.mwis} />
                    <Stat name="Intelligence" base={playerStats.intel} mod={playerStats.mintel} />
                    <Stat name="Charisma" base={playerStats.chr} mod={playerStats.mchr} />
                    <Stat name="Gold" base={formatGold} mod={0} />
                </section>
            </section >
        </>
    );
}

export default CharacterPanel;