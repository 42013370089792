import React from "react";
import "./info.css";

function Info({ name, value }) {
    return (
        <>
            <div className="infobox">
                <div className="box-item" style={{color: 'skyblue'}}>{name}</div>
                <div className="box-item">{value}</div>
            </div>
        </>
    )
}

export default Info;