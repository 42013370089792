import React, { useState } from "react";
import './progressbar.css';

function Bar({ experience }) {
    const [showPercentage, setShowPercentage] = useState(true);
    const [freeze, setFreeze] = useState(false);
    const percentage = ((100 * experience.cur) / experience.max).toFixed(3);
    const width = percentage > 100 ? '100%' : (percentage < 0 ? '0%' : percentage + '%');

    const handleMouseEnter = (e) => {
        freeze ? setShowPercentage(true) : setShowPercentage(false);
    }

    const handleMouseExit = (e) => {
        freeze ? setShowPercentage(false) : setShowPercentage(true);
    }
    const handleFreeze = (e) => {
        setFreeze(!freeze);
    }

    return (
        <div className="progress">
            <div className="progress-outer">
                <div className="health-info" onMouseOver={handleMouseEnter} onMouseOut={handleMouseExit} onClick={handleFreeze}>
                    <p>Experience: {showPercentage ? percentage + '%' : experience.cur + ' / ' + experience.max}</p>
                </div>
                <div className="progress-inner" style={{ width: width }} >
                </div>
            </div>
        </div>
    );
}

export default Bar;