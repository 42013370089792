import React, { useState } from "react";
import './healthbar.css';

function HealthBar({ cur, max, name }) {
    const [showPercentage, setShowPercentage] = useState(false);
    const [freeze, setFreeze] = useState(false);
    const percentage = ((100 * cur) / max).toFixed(0);
    const width = percentage > 100 ? '100%' : (percentage < 0 ? '0%' : percentage + '%');

    const handleMouseExit = (e) => {
        freeze ? setShowPercentage(true) : setShowPercentage(false);
    }

    const handleMouseEnter = (e) => {
        freeze ? setShowPercentage(false) : setShowPercentage(true);
    }
    const handleFreeze = (e) => {
        setFreeze(!freeze);
    }

    return (
        <div className="vitials">
            <div className={name.toLowerCase() + "-outer"}>
                <div className={name.toLowerCase() + "-inner"}
                    style={{ width: width }} >
                </div>
                <div className="health-info"
                    onMouseOver={handleMouseEnter}
                    onMouseOut={handleMouseExit}
                    onClick={handleFreeze}>
                    <p>{name}: {showPercentage ? percentage + '%' : cur + ' / ' + max} </p>
                </div>
            </div>
        </div>
    );
}

export default HealthBar;