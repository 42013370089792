import React from "react";
import logo from './Assets/clientHeader.png';
import './LoginConnecting.css';

function LoginConnecting({connecting, connected, register}) { 

    return (
        <div className='wrapper'>
            <img src={logo} alt="Logo" />
            <div className="loader-wrapper">
                <div className="loader"></div>
                {connecting && !connected && <h2>Connecting...</h2>}
                {connected && !register &&<h2>Logging in...</h2>}              
                {connected && register &&<h2>Creating...</h2>}              
            </div>
            <div>
            </div>
        </div>
    );
}

export default LoginConnecting;