import React from 'react';
import './mapgrid.css';

function MapGrid({ area, mapInfo, position }) {
    return (
        <div>
            <div className="map-grid">
                {mapInfo.map((item, index) => (<div key={index} className='map-grid-item' style={{ backgroundColor: item !== '5,5,5' ? "rgba(" + item + ")" : 'rgba(0,0,0,0.35)' }}></div>))}
                <div className='dot' style={{ gridColumn: position[0], gridRow: position[1] }}>
                    <span className="material-symbols-outlined">
                        accessibility_new
                    </span>
                </div>
            </div>
            <div className='room-title' style={{ borderWidth: '2px', marginTop: '2px', fontSize: '14px' }}>{area}</div>
        </div>
    );
}

export default MapGrid;