import React from "react";
import './PortraitSelector.css';

function PortraitSelector({selectedImage, setSelectedImage, setShowPortraitSelector}) {

    const image = [657,659,660,661,662,663,664,665,666,667,668,669,670,671,672,673,674,675,676,677];

    return (
        <div className="portrait-selector">
            <div className="port-content">
                <h1>Select a Portrait</h1>
                <div className="img-grid">
                    {image.map(e =>
                         <img className={e === selectedImage ? 'selected' : 'pimg'}
                          src={require('../../Assets/gfx/' + e + '.png')} 
                          onClick={()=> setSelectedImage(e)}
                          alt=""/>)}
                </div>
                {selectedImage < 1 ? 
                <button className="sign" disabled>Confirm</button>
                :    
                <button className="sign" onClick={()=> setShowPortraitSelector(false)}>Confirm</button>
            }
            </div>
        </div>
    );
}

export default PortraitSelector;