import React, { useEffect, useCallback, useState } from "react";
import './Hotkeys.css';

function Hotkeys({ displayModal, sendMessage, actions, setActions }) {
    

    const keys = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9'];

    const [actionId, setActionId] = useState(0);


    const handleKeyPress = useCallback((event) => {
        const { code } = event;
        const action = actions[actionId].find(action => action.key === code);
        if (action) {
            fire(action.cmd);
        }

        if (keys.includes(code)) {
            event.preventDefault();
        }

    }, [actions, actionId]);

    const handleKeyRelease = useCallback((event) => {

    }, [actions, actionId]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        window.addEventListener('keyup', handleKeyRelease)

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
            window.removeEventListener('keyup', handleKeyRelease);
        };
    }, [handleKeyPress, handleKeyRelease]);

    const fire = (hotkey) => {
        if (hotkey === '/hotkey') {
            displayModal('hotkey');
            return;
        }
        if (hotkey && hotkey.length > 0) {
            sendMessage('600' + hotkey);
        }
    }

    const switchHotkey = (index) => {
        if (actions.length <= index) {
            return;
        }
        setActionId(index);
    }

    return (
        <>
            <div className="hotkeys">
                {keys.map((key) => {
                    const action = actions[actionId].find(action => action.key === key);
                    return (
                        <button
                            key={key}
                            className="hotkey-button"
                            onClick={() => action && fire(action.cmd)}
                            disabled={!action}
                        >

                            {action ? action.name : ''}
                        </button>

                    );

                })}

            </div>
            <div className="selector">
                <button className={"selector-button" + (actionId === 0 ? ' selector-selected' : '')} onClick={() => switchHotkey(0)}>1</button>
                <button className={"selector-button" + (actionId === 1 ? ' selector-selected' : '')} onClick={() => switchHotkey(1)}>2</button>
                <button className={"selector-button" + (actionId === 2 ? ' selector-selected' : '')} onClick={() => switchHotkey(2)}>3</button>
                <button className={"selector-button" + (actionId === 3 ? ' selector-selected' : '')} onClick={() => switchHotkey(3)}>4</button>
                {/* <span className="material-symbols-outlined edit">
                    edit_note
                </span> */}
            </div>

        </>
    );
}

export default Hotkeys;
