import React from "react";
import "./stat.css";

function Stat({ name, base, mod }) {
    return (
        <>
            <div className="statbox">
                <div className="stat-item key">{name}</div>
                <div className={"stat-item " + name}>{base}</div>
                {mod !== 0 && <div className="stat-item"><p className={mod > 0 ? 'sgreen' : 'sred'}>{mod > 0 && '+'}{mod}</p></div>}
            </div>
        </>
    );
}


export default Stat;