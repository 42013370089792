// import React, { useState, useEffect, useRef } from "react";

// function StaminaBar({ stamina }) {
//     const [time, setTime] = useState(0);
//     const [isRunning, setIsRunning] = useState(true);
//     const [width, setWidth] = useState("0%");

//     useEffect(() => {
//         let intervalId;

//         if (isRunning) {
//             // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
//             intervalId = setInterval(() => setTime(time + 100), 100);
//             if (time >= stamina.duration) {
//                 setIsRunning(false);
//                 setTime(0);
//             }
//             setWidth(Math.ceil((time * 100) / stamina.duration) + "%");
//         }
//         return () => clearInterval(intervalId);
//     }, [isRunning, time]);


//     useEffect(() => {
//         reset();
//     }, [stamina]);

//     const reset = () => {
//         setTime(0);
//         setIsRunning(true);
//     }

//     const displayType = () => {
//         const timeStr = (time / 1000) + "s / " + (stamina.duration / 1000) + "s";

//         switch (stamina.type.toLowerCase()) {
//             case "stamina":
//                 return <p> Stamina: {time === 0 ? 'Ready' : timeStr}</p>
//             case "stunned":
//                 return <p> Stunned: {timeStr}</p>
//             case "respawn":
//                 return <p> Respawn in {(stamina.duration / 1000) - (time / 1000) + "s"}</p>
//             default:
//                 return <p> Casting: {timeStr}</p>
//         }
//     }

//     return (
//         <>
//             <div className="vitials">
//             <div className="stamina-outer">
//                 <div className={"stamina-inner " + stamina.type.toLowerCase()} style={{ width:  time === 0 ? '100%' : width }} ></div>
//                 <div className="health-info">
//                     {displayType()}
//                 </div>
//             </div>

//             </div>
//         </>
//     );
// }

// export default StaminaBar;

import React, { useState, useEffect, useRef } from "react";

function getDisplayType(time, duration, type) {
    const timeStr = (time / 1000) + "s / " + (duration / 1000) + "s";

    switch (type.toLowerCase()) {
        case "stamina":
            return <p> Stamina: {time === 0 ? 'Ready' : timeStr}</p>
        case "stunned":
            return <p> Stunned: {timeStr}</p>
        case "respawn":
            return <p> Respawn in {(duration / 1000) - (time / 1000) + "s"}</p>
        default:
            return <p> Casting: {timeStr}</p>
    }
}

function StaminaBar({ stamina }) {
    const time = useRef(0);
    const [isTimerActive, setIsTimerActive] = useState(true);
    const [width, setWidth] = useState("0%");

    useEffect(() => {
        let intervalId;

        if (isTimerActive) {
            intervalId = setInterval(() => {
                time.current += 100;
                if (time.current >= stamina.duration) {
                    setIsTimerActive(false);
                    time.current = 0;
                }
                setWidth(`${Math.ceil((time.current * 100) / stamina.duration)}%`);
            }, 100);
        }

        return () => clearInterval(intervalId);
    }, [isTimerActive, stamina.duration]);

    useEffect(() => {
        time.current = 0;
        setIsTimerActive(true);
    }, [stamina]);

    return (
        <>
            <div className="vitials">
            <div className="stamina-outer">
                <div className={"stamina-inner " + stamina.type.toLowerCase()} style={{ width:  time.current === 0 ? '100%' : width }} ></div>
                <div className="health-info">
                    {getDisplayType(time.current, stamina.duration, stamina.type)}
                </div>
            </div>
            </div>
        </>
    );
}

export default StaminaBar;


// // import React, { useEffect, useRef } from "react";
// // import './StaminaBar.css'; // Import the CSS file

// function StaminaBar({ stamina }) {
//     const barRef = useRef(null);

//     useEffect(() => {
//         console.log("useeffect stamina")
//         if (barRef.current) {
//             barRef.current.style.animation = `increaseWidth 3s linear`;
//         }
//     }, [stamina]);

//     return (
//         <div className="vitials">
//             <div className="stamina-outer">
//                 <div ref={barRef} className={"stamina-inner " + stamina.type.toLowerCase()}></div>
//             </div>
//         </div>
//     );
// }

// export default StaminaBar;