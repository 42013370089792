import React, { useState } from "react";
import './Shop.css';

function Shop({ displayModal, shopList, shopInfo, sendMessage, gold }) {
    const [sellMode, setSellMode] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [amount, setAmount] = useState(1);


    function getItemInfo(item, itemId, index, cost) {
        if (sellMode) {
            sendMessage('316' + shopList.shopId + ';shop sinfo ' + index + ";" + itemId);
        } else {
            sendMessage('316' + shopList.shopId + ';shop info ' + item + ";" + itemId);
        }
        setSelectedItem({ item, itemId, index, cost });
        setAmount(1);
    }

    function buyItem() {
        if (selectedItem) {
            if (sellMode) {
                sendMessage('316' + shopList.shopId + ";shop sell " + amount + "," + selectedItem.index + "," + selectedItem.itemId);
            } else {
                sendMessage('316' + shopList.shopId + ";shop buy " + amount + "," + selectedItem.item + "," + selectedItem.itemId);
            }
        }
    }

    function getSellItems() {
        if (!sellMode) {
            setSellMode(true);
            setSelectedItem(null);
            sendMessage('316' + shopList.shopId + ';shop blist');
        }
    }

    function getBuyItems() {
        if (sellMode) {
            setSellMode(false);
            setSelectedItem(null);
            sendMessage('316' + shopList.shopId + ';shop');
        }
    }

    function BuyAmount() {
        return (
            <div className="buy-amount">
                <div className="buy-wrap">
                    <span className="total">Total: </span><p className="total-cost">{selectedItem && (amount * selectedItem.cost).toLocaleString()}</p>
                    <span className="total">x</span>
                    <input type="number" className="amount" min={1} max={500} defaultValue={amount}
                        onChange={(e) => setAmount(Number(e.currentTarget.value))} />
                </div>
            </div>
        );
    }

    return (
        <div className="modal" style={{ display: "block" }}>
            <div className="modal-content" style={{ width: '900px' }}>
                <div className="tmodal-header">
                    <span className="close" onClick={displayModal}>&times;</span>
                    <h2>Shop</h2>
                </div>
                <div className="modal-body" style={{ width: '100%', padding: '2px 5px' }}>
                    <div className="shop" style={{height: '600px'}} >
                        <div>
                            <ShopOptions getSellItems={getSellItems} getBuyItems={getBuyItems} sellMode={sellMode} />
                            <ul className="shop-list">
                                {shopList?.list?.map((e, i) => <ShopItem key={e.itemId} item={e} itemInfo={getItemInfo} index={i} selected={selectedItem?.itemId} />)}
                            </ul>
                        </div>
                        <div className="shop-info">
                            <p className="ability-points">Available Gold (<span className="available-gold-amount">{gold.toLocaleString()}</span>)</p>

                            <div className="info-header">
                                <p>Information</p>
                            </div>
                            {shopInfo &&
                                <>
                                    <div className="info-desc">
                                        <Information shopInfo={shopInfo} />
                                        <p className="description">{shopInfo.description}</p>
                                        <div className="stats">
                                            <Requirements reqs={shopInfo.requirements} />
                                            <Modifiers mods={shopInfo.modifiers} uniMods={shopInfo.uniModifiers} />
                                        </div>
                                    </div>
                                    {shopInfo.maxSockets > 0 && <SocketItem shopInfo={shopInfo} />}
                                </>
                            }
                            <div className="bottom">
                                <button className="ts-button" onClick={e => buyItem(1)}>{sellMode ? 'Sell' : 'Buy'}</button>
                                <BuyAmount setAmount={setAmount} cost={300} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="ts-close" onClick={displayModal}>Close</button>
                </div>
            </div>
        </div>
    );
}

export function Information({ shopInfo }) {
    return (
        <>
            {(shopInfo.type === 'Armour' || shopInfo.type === 'Shield') && <DisplayArmour shopInfo={shopInfo} />}
            {(shopInfo.type === 'Other' || shopInfo.type2 === 'Use') && <DisplayOther shopInfo={shopInfo} />}
            {shopInfo.type === 'Container' && <DisplayContainer shopInfo={shopInfo} />}
            {shopInfo.type?.startsWith('Weapon') && <DisplayWeapon shopInfo={shopInfo} />}
        </>
    );
}



function DisplayContainer({ shopInfo }) {
    return (
        <div className="info-info">
            <p className="name">Name <span className="value">{shopInfo.name}</span></p>
            <p className="name">Capacity <span className="value">{shopInfo.val}/{shopInfo.speed}</span></p>
        </div>
    );
}

function DisplayWeapon({ shopInfo }) {
    return (
        <div className="info-info">
            <p className="name">Name <span className="value">{shopInfo.name}</span></p>
            <p className="name">Type <span className="value">{shopInfo.type.slice(7)}</span></p>
            <p className="name">Slot <span className="value">{shopInfo.type2}</span></p>
            <p className="name">Damage <span className="value">{shopInfo.val}</span></p>
            <p className="name">Speed <span className="value">{shopInfo.speed}</span></p>
            <p className="name">Sockets <span className="value">{shopInfo.maxSockets}</span></p>
        </div>
    );
}

function DisplayArmour({ shopInfo }) {
    return (
        <div className="info-info">
            <p className="name">Name <span className="value">{shopInfo.name}</span></p>
            <p className="name">Type <span className="value">{shopInfo.type}</span></p>
            <p className="name">Slot <span className="value">{shopInfo.type2}</span></p>
            <p className="name">Armour <span className="value">{shopInfo.val}</span></p>
            <p className="name">Speed <span className="value">{shopInfo.speed}</span></p>
            <p className="name">Sockets <span className="value">{shopInfo.maxSockets}</span></p>
        </div>
    );
}

function DisplayOther({ shopInfo }) {
    return (
        <div className="info-info">
            <p className="name">Name <span className="value">{shopInfo.name}</span></p>
            <p className="name">Type <span className="value">{shopInfo.type}</span></p>
        </div>
    );
}

export function SocketItem({ shopInfo }) {
    return (
        <>
            <div className="sockets">
                <h3>Sockets</h3>
                {shopInfo.socketItems?.map((e, i) => <li key={i}><span className="socket-name">{e.name !== '' && <>{e.name}</>}</span><span className="socket-value">{e.value.slice(0,e.value.lastIndexOf(' '))}</span><span className="socket-value">{e.value.slice(e.value.lastIndexOf(' '))}</span></li>)}
            </div>
        </>
    )
}

function Attribute({ name, value }) {
    return (
        <div className="attrib">
            <p className="attrib-name">{name}</p>
            <p className="attrib-value">{value}</p>
        </div>);
}

export function Requirements({ reqs }) {
    return (
        <>
            <div className="requirements">
                <h3>Requirements</h3>
                {reqs?.map((r, i) => <li key={i} className={r.meetsRequirement === true ? 'green' : 'red'}><Attribute name={r.name} value={r.val} /></li>)}
            </div>
        </>
    );
}
export function Modifiers({ mods, uniMods }) {
    return (
        <>
            <div className="modifiers">
                <h3>Modifiers</h3>
                {mods?.map((r, i) => <li key={i}><Attribute name={r.name} value={r.val} /></li>)}
                {uniMods?.map((r, i) => <li key={i} style={{ color: '#c46013' }}><Attribute name={r.name} value={r.val} /></li>)}
            </div>
        </>
    );
}
function ShopOptions({ getSellItems, getBuyItems, sellMode }) {
    return (
        <div className='shop-option'>
            <button className={"btn-option" + (!sellMode ? ' selected' : '')} onClick={getBuyItems}>Buy</button>
            <button className={"btn-option" + (sellMode ? ' selected' : '')} onClick={getSellItems}>Sell</button>
        </div>
    );
}


function ShopItem({ item, itemInfo, index, selected }) {
    return (
        <li style={{ borderColor: selected === item.itemId ? 'white' : '' }}>
            <div className="item" onClick={(e) => itemInfo(item.name, item.itemId, index, item.cost)}>
                <img src={require('../../Assets/gfx/' + item.picId + '.png')} alt=""/>
                <div className="item-info">
                    <span className="item-name" style={{ color: item.colour }}>
                        {item.name} {item.amount > 1 && <> ({item.amount})</>} {item.maxSockets > 0 && <> [{item.curSockets}/{item.maxSockets}]</>}
                        {item.maxCap > 0 && <> [{item.curCap}/{item.maxCap}]</>}
                    </span>
                    <span className="tag">

                    </span>
                    <div className="cost">
                        <p>Gold: <span className="gold">{item.cost.toLocaleString()}</span></p>
                    </div>
                </div>
            </div>
        </li>
    );
}


export default Shop;