import React, { useState, useEffect } from 'react';
import './Login.css';
import './Account.css';
import logo from './Assets/clientHeader.png';
import CharacterList from './CharacterList';
import CreateCharacter from './Components/CreateCharacter/CreateCharacter';

function Account({ characterList, onClick, createInfo }) {
    const [showCreate, setShowCreate] = useState(false);

    useEffect(() => {
        if (createInfo === null) {
            onClick('100');
        }
    }, [createInfo, onClick]);

    const toggleCreate = () => {
        setShowCreate(!showCreate);
    }

    return (
        <div className='wrapper' >
            <div style={{ maxWidth: '450px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}>
                <img src={logo} alt="Logo" />
            </div>
            {showCreate ?

                <CreateCharacter createInfo={createInfo} sendMessage={onClick} toggleCreate={toggleCreate}/>
                :
                <AccountPanel characterList={characterList} onClick={onClick} />
            }
            <button className='register' onClick={(e) => toggleCreate()}> {showCreate ? "Back" : "Create Character"}</button>

        </div>
    );
}


function AccountPanel({ characterList, onClick }) {

    const [search, setSearch] = useState('');

    const filteredList = characterList.filter((character) => {
        return character.name.startsWith(search);
    });

    const submit = () => {
        const char = filteredList;
        if(char.length > 0) {
            onClick('002' + char[0].name);
        }
    }

    return (
        <>
            <div className="account-options">
                <div className='account-search'>
                    <span class="material-symbols-outlined">
                        search
                    </span>
                    <input type="search" placeholder='Search' 
                    onKeyUp={(e) => e.key === 'Enter' && submit()}
                    onChange={(e) => setSearch(e.currentTarget.value)} 
                    autoFocus />
                </div>
                <div className='account-settings'>
                    <button>
                        Account Settings
                    </button>
                </div>
            </div>
            {search.length > 0 ?
                <CharacterList characterList={filteredList} onClick={onClick} />
                :
                <CharacterList characterList={characterList} onClick={onClick} />
            }
        </>
    );
}

export default Account;