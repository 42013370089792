import React, { useState, lazy } from 'react';
import Account from './Account';
import Login from './Login';
import Main from './Main';

let socket = null;

// const URL = "ws://localhost:9999";
const URL = "https://cl.cursed-lands.net";

export const AppState = React.createContext();

function Home() {

    const [loggedIn, setLoggedIn] = useState(false);
    const [connected, setConnected] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [characterList, setCharacterList] = useState([{ id: 1, name: "test", picId: 1, level: 24, race: "Human", clazz: "Warrior" }]);
    const [playing, setPlaying] = useState(false);
    const [messages, setMessages] = useState([]);
    const [roomListPlayers, setRoomListPlayers] = useState([]);
    const [roomListMobs, setRoomListMobs] = useState([]);
    const [mapGrid, setMapGrid] = useState([]);
    const [position, setPosition] = useState([5, 5]);
    const [roomInfo, setRoomInfo] = useState({ area: "area", title: "title", exits: "exits", desc: "desc" });
    const [playerStats, setPlayerStats] = useState({});
    const [playerInfo, setPlayerInfo] = useState({});
    const [health, setHealth] = useState({ cur: 10, max: 300 });
    const [mana, setMana] = useState({ cur: 30, max: 100 });
    const [experience, setExperience] = useState({ cur: 10, max: 100 });
    const [stamina, setStamina] = useState({ type: '', duration: '' });
    const [gold, setGold] = useState(0);
    const [trainer, setTrainer] = useState([]);
    const [abilityInfo, setAbilityInfo] = useState([]);
    const [statPoints, setStatPoints] = useState(0);
    const [buffs, setBuffs] = useState([]);
    const [shopList, setShopList] = useState([]);
    const [shopInfo, setShopInfo] = useState([]);
    const [inventory, setInventory] = useState({});
    const [inventoryInfo, setInventoryInfo] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [createInfo, setCreateInfo] = useState(null);

    var mobs = [];
    var players = [];

    function registerAccount(username, password, email) {
        if (socket === null) {
            setConnecting(true);
            socket = new WebSocket(URL);
            socket.addEventListener("open", event => {
                setConnected(true);
                console.log("connection opened");
                socket.send("001" + username + password + ";" + email);
            });
            socket.addEventListener("close", event => {
                setConnected(false);
                setConnecting(false);
                setLoggedIn(false);
                setPlaying(false);
                console.log("connection closed");
                socket.close();
                socket = null;
            });
            socket.addEventListener("message", event => {
                const splitData = event.data.split("!#~");
                for (let i = 0; i < splitData.length; i++) {
                    parseData(splitData[i]);
                }
            });
            socket.addEventListener("error", event => {
                console.log("there was an error!");
            });
        } else {
            setConnecting(false);
        }
    }

    function connect(username, password) {
        if (socket == null) {
            setConnecting(true);
            socket = new WebSocket(URL);
            socket.addEventListener("open", event => {
                setConnected(true);
                console.log("connection opened");
                socket.send("000" + username + password);
            });
            socket.addEventListener("close", event => {
                setConnected(false);
                setConnecting(false);
                setLoggedIn(false);
                setPlaying(false);
                console.log("connection closed");
                socket.close();
                socket = null;
            });
            socket.addEventListener("message", event => {
                const splitData = event.data.split("!#~");
                for (let i = 0; i < splitData.length; i++) {
                    parseData(splitData[i]);
                }
            });
            socket.addEventListener("error", event => {
                console.log("there was an error!");
            });
        } else {
            setConnecting(false);
        }
    }

    function parseData(recieved) {
        const type = recieved.substring(0, 3);
        const data = recieved.substring(3, recieved.length);

        if (type === "200") {
            console.log(data);
            return;
        }

        //update email....
        if (type === "020") {
        }
        if (type === "004") {
            setLoggedIn(true);
            const splitChar = data.split("/");
            const characters = [];
            for (let i = 0; i < splitChar.length - 1; i++) {
                const charInfo = splitChar[i].split("-");
                const picId = charInfo[0];
                const name = charInfo[1];
                const level = charInfo[2];
                const race = charInfo[3];
                const clazz = charInfo[4];
                characters.push({ id: i, name: name, picId: picId, level: level, race: race, clazz: clazz });
            }
            setCharacterList(characters);
            return;
        }
        if (type === "002") {
            const pSplit = data.split('-');
            const pInfo = {
                picId: pSplit[0],
                name: pSplit[1],
                level: pSplit[2],
                race: pSplit[3],
                clazz: pSplit[4]
            };
            setPlayerInfo(pInfo);
            setPlaying(true);
            return;
        }

        if (type === "100") {
            updateCreateCharacter(data);
            return;
        }

        if (type === "312") {
            mobs = [];
            players = [];
            setRoomListMobs(mobs);
            setRoomListPlayers(players);
            return;
        }

        if (type === "303") {
            updateStamina(data);
            return;
        }

        if (type === "301") {
            updateHealth(data);
            return;
        }
        if (type === "302") {
            updateMana(data);
            return;
        }

        if (type === "304") {
            updateExperience(data);
            return;
        }

        if (type === "315") {
            updateGold(data);
        }

        if (type === "300") {
            updatePlayerStats(data);
            return;
        }

        if (type === "306") {
            updateMapPosition(data);
            return;
        }

        if (type === "305") {
            updateMap(data);
            return;
        }

        if (type === "314") {
            updateRoomInfo(data);
            return;
        }

        if (type === "345") {
            if (data.includes('/')) {
                const split = data.split("/");
                // const isMob = split[0] === true;
                const id = split[2];

                mobs.forEach(m => {
                    if (m.id === id) {
                        m.selected = true;
                    } else {
                        m.selected = false;
                    }
                })

                players.forEach(p => {
                    if (p.id === id) {
                        p.selected = true;
                    } else {
                        p.selected = false;
                    }
                })

            } else if (data === "clear") {
                mobs.forEach(m => {
                    m.selected = false;
                })
                players.forEach(p => {
                    p.selected = false;
                })
            }
            setRoomListMobs([...mobs]);
            setRoomListPlayers([...players]);
            return;
        }

        if (type === "307") {
            const split = data.split("/");
            if (split.length === 6) {
                const picId = split[0];
                const name = split[1];
                const hp = parseInt(split[2]);
                const friendStatus = split[3];
                // const sneak = split[4];
                const level = split[5];

                const index = players.findIndex((player) => player.name === name);
                if (index === -1) {
                    players.push({ type: "player", id: name, name: name, level: level, picId: picId, hp: hp, friendStatus: friendStatus, attacking: 0, selected: false });
                } else {
                    players[index].hp = hp;
                }
                setRoomListPlayers([...players]);

            } else if (split.length >= 7) {
                // const pet = split[0]
                const id = split[1];
                const picId = split[2];
                const name = split[3];
                const hp = parseInt(split[4]);
                const friendStatus = parseInt(split[5]);
                // const sneak = split[6];
                const level = split[7];
                const actions = [];
                const attacking = 0;

                if (split.length === 9) {
                    const action = split[8].split(';');
                    action.forEach(a => {
                        a.length > 0 && actions.push(a);
                    });
                }

                const index = mobs.findIndex((mob) => mob.id === id);
                if (index === -1) {
                    mobs.push({ type: "mob", id: id, name: name, level: level, picId: picId, hp: hp, friend: friendStatus, actions: actions, attacking: attacking, selected: false });
                } else {
                    mobs[index].hp = hp;
                }
                setRoomListMobs([...mobs]);
            }
            return;
        }


        if (type === "308") {
            updateDisplay(data);
            return;
        }

        if (type === "311") {
            mobs = mobs.filter((m) => m.id !== data);
            players = players.filter((p) => p.name !== data);
            setRoomListMobs(mobs);
            setRoomListPlayers(players);
            return;
        }

        if (type === "317") {
            updateTrainerInfo(data);
            return;
        }

        if (type === "318") {
            updateAbilityInfo(data);
            return;
        }

        if (type === '319') {
            updateInventory(data);
            return;
        }

        if (type === '320') {
            updateEquipment(data);
            return;
        }

        if (type === '322') {
            updateInventoryInfo(data);
            return;
        }

        if (type === "323") {
            updateShop(data);
            return;
        }
        if (type === "324") {
            updateShopInfo(data);
            return;
        }

        if (type === '501') {
            updateBuffs(data);
            return;
        }

        if (type === '502') {
            removeBuff(data);
            return;
        }

        if (type === '604') {
            //update stats
            const stats = data.split(';');
            // const str = parseInt(stats[0]);
            // const agi = parseInt(stats[1]);
            // const tog = parseInt(stats[2]);
            // const wis = parseInt(stats[3]);
            // const intel = parseInt(stats[4]);
            // const chr = parseInt(stats[5]);
            const points = parseInt(stats[6]);
            setStatPoints(points);
            return;
        }

        if (type === '605') {
            updateAggro(data);
            return;
        }

    }

    function updateCreateCharacter(data) {
        const details = data.split('_');
        const raceDetails = details[0];
        const classDetails = details[1];
        const statPoints = details[2];

        const er = raceDetails.split('/');
        const races = [];
        for (let i = 0; i < er.length - 1; i++) {
            const rd = er[i].split(';');
            const rname = rd[0];
            const raceDesc = rd[1];
            const str = parseInt(rd[2]);
            const agi = parseInt(rd[3]);
            const tog = parseInt(rd[4]);
            const intel = parseInt(rd[5]);
            const wis = parseInt(rd[6]);
            const chr = parseInt(rd[7]);
            const raceAttributes = rd[8].split('~');

            races.push({ name: rname, description: raceDesc, str, agi, tog, intel, wis, chr, raceAttributes });
        }

        const ec = classDetails.split("/");
        const classList = [];
        for (let i = 0; i < ec.length - 1; i++) {
            const cd = ec[i].split(";");
            const className = cd[0];
            const classDesc = cd[1];
            // const attribs = cd[3];

            classList.push({ name: className, description: classDesc });
        }

        const info = { statPoints, races, classList };
        setCreateInfo(info);
    }

    function updateTrainerInfo(data) {
        const info = data.split('~');
        const id = info[0].split(' ');
        const abil = [];

        for (let i = 1; i < info.length; i++) {
            const ability = info[i].split(';');
            if (ability.length > 1) {
                const picId = parseInt(ability[0]);
                const isSpell = ability[1];
                const name = ability[2];
                const level = parseInt(ability[3]);
                const cap = parseInt(ability[4]);
                const type = ability[5];
                const b = ability[6] === "1";
                abil.push({ picId: picId, isSpell: isSpell, name: name, level: level, cap: cap, type: type, b: b });
            }

        }

        const trainer = {
            id: id[0],
            points: id[1],
            abilities: abil
        };

        setTrainer(trainer);
    }

    function updateAbilityInfo(data) {
        const info = data.split(';');
        if (info.length >= 4) {
            const reqs = parseAbilityRequirements(info[0]);
            const mods = parseAbilityModifiers(info[1]);
            const name = info[2];
            const level = parseInt(info[3]);
            const desc = info[4];
            const type = info[5];
            const type2 = info[6];
            let castTime = 0;
            let cooldown = 0;
            let reuse = 0;
            if (info.length >= 8) {
                castTime = parseInt(info[7]);
                cooldown = parseInt(info[8]);
            }
            if (info.length === 9) {
                reuse = parseInt(info[9]);
            }
            setAbilityInfo({ name: name, level: level, desc: desc, type: type, requirements: reqs, modifiers: mods, type2: type2, castTime: castTime, cooldown: cooldown, reuse: reuse });
        }
    }

    function updateAggro(data) {
        const info = data.split(';');
        const id = info[0];
        const isMob = parseInt(info[1]) === 1;
        const isAggro = parseInt(info[2]);
        if (isMob) {
            const index = mobs.findIndex((mob) => mob.id === id);
            if (index !== -1) {
                mobs[index].attacking = isAggro;
            }
            setRoomListMobs([...mobs]);
        }
    }

    function updateEquipment(data) {
        const info = data.split(';');
        const equip = [];

        for (let i = 0; i < info.length - 1; i++) {
            const eInfo = info[i].split(',');
            const picId = parseInt(eInfo[0]);
            const type = eInfo[1];
            const colour = eInfo[2].replace('{c}', '#');
            const name = eInfo[3];
            const slot = eInfo[4];
            equip.push({ picId, type, colour, name, slot });
        }

        setEquipment(equip);
    }

    function updateInventory(data) {
        if (data.includes(';')) {
            const parse = data.split(';');
            const [invCount, invMax] = parse[0].split(',').map(Number);
            const itemList = [];

            for (let i = 1; i < parse.length - 1; i++) {
                const itemData = parse[i].split(',');
                const picId = parseInt(itemData[0]);
                const colour = itemData[1].replace('{c}', '#');
                const name = itemData[2];
                const qty = parseInt(itemData[3]);
                const uni = itemData[4];
                const type = itemData[5];
                const slot = itemData[6];
                let tag = '';
                let conItems = 0;
                let cap = 0;
                let maxSockets = 0;
                let usedSockets = 0;
                if (itemData.length > 8) {
                    tag = itemData[7];
                }

                if (itemData.length >= 12) {
                    conItems = parseInt(itemData[8]);
                    cap = parseInt(itemData[9]);
                    maxSockets = parseInt(itemData[10]);
                    usedSockets = parseInt(itemData[11]);
                }
                itemList.push({ picId, colour, name, qty, uni, type, slot, tag, conItems, cap, maxSockets, usedSockets });
            }
            setInventory({ invCount, invMax, itemList });
        }
    }

    function updateShop(data) {
        if (data.includes(';')) {
            const split = data.split(';');
            const id = split[0];
            const list = [];
            for (let i = 1; i < split.length - 1; i++) {
                const itemData = split[i].split(',');
                const itemId = itemData[0];
                const picId = itemData[1];
                const colour = itemData[2].replace('{c}', '#');
                const curSockets = parseInt(itemData[3]);
                const maxSockets = parseInt(itemData[4]);
                const curCap = parseInt(itemData[5]);
                const maxCap = parseInt(itemData[6]);
                const name = itemData[7];
                const type = itemData[8];
                const type2 = itemData[9];
                const cost = parseInt(itemData[10]);
                let amount = 1;
                let isUnique = false;
                let tag = "";
                if (itemData.length >= 13) {
                    amount = parseInt(itemData[11]);
                    isUnique = itemData[12];
                    tag = itemData[13];
                }

                list.push({
                    itemId,
                    picId,
                    colour,
                    curSockets,
                    maxSockets,
                    curCap,
                    maxCap,
                    name,
                    type,
                    type2,
                    cost,
                    amount,
                    isUnique,
                    tag
                })
            }
            setShopList({ shopId: id, list: list });
        }
    }

    function updateShopInfo(data) {
        if (data.includes(';')) {
            const itemInfo = getItemInfo(data);
            setShopInfo(itemInfo);
        }
    }

    function updateInventoryInfo(data) {
        if (data.includes(';')) {
            const itemInfo = getItemInfo(data);
            setInventoryInfo(itemInfo);
        }
    }

    function getItemInfo(data) {
        const parse = data.split(';');
        const picId = parseInt(parse[0]);
        const name = parse[1];
        const description = parse[2];
        const type = parse[3];
        const type2 = parse[4];
        const val = parseInt(parse[5]);
        const speed = parseInt(parse[6]);
        const requirements = [];
        const modifiers = [];
        const uniModifiers = [];
        const socketItems = [];
        // const procs = [];

        let curSockets = 0;
        let maxSockets = 0;

        if (parse.length >= 8) {
            const req = parse[7];
            if (req.includes('/')) {
                let split = req.split('/');
                split.forEach((e, i) => {
                    if (i < split.length - 1) {
                        const d = e.split(':');
                        const meetsRequirement = (d[2] === 'true');
                        requirements.push({ name: d[0], val: d[1], meetsRequirement });
                    }
                });
            }
        }
        if (parse.length >= 9) {
            const mods = parse[8];
            if (mods.includes('/')) {
                let split = mods.split('/');
                split.forEach((e, i) => {
                    if (i < split.length - 1) {
                        const d = e.split(':');
                        modifiers.push({ name: d[0], val: d[1] });
                    }
                });
            }
        }
        if (parse.length >= 10) {
            for (let i = 9; i < parse.length; i++) {
                if (!parse[i].includes(' ')) {
                    continue;
                }

                const modType = parse[i].slice(0, parse[i].indexOf(' ')).trim();
                const data2 = parse[i].slice(parse[i].indexOf(' '), parse[i].length).trim();

                switch (modType) {
                    case 'mod':
                        if (data2.includes('/')) {
                            const umod = data2.split('/');
                            umod.forEach(m => {
                                if (m.includes(':')) {
                                    const info = m.split(':');
                                    if (info.length > 0) {
                                        uniModifiers.push({ name: info[0], val: info[1] });
                                    }
                                }
                            });
                        }
                        break;
                    case 'sock':
                        if (data2.includes("/")) {
                            const split2 = data2.substring(data2.indexOf("/") + 1).trim().split("/");
                            const sdata = data2.substring(0, data2.indexOf("/"));
                            const s2 = sdata.split(":");
                            maxSockets = parseInt(s2[0].trim());
                            curSockets = parseInt(s2[1].trim());
                            let count = 1;
                            for (const s of split2) {
                                const pr = s.split(":");
                                if (pr.length === 2) {
                                    const rName = pr[0].trim();
                                    const rVal = pr[1].trim();
                                    const mods = rVal.split(",");
                                    for (let mi = 0; mi < mods.length; mi++) {
                                        if (mods[mi].length > 0) {
                                            if (mi === 0) {
                                                socketItems.push({ name: count++ + '. ' + rName, value: mods[mi] });
                                            } else {
                                                socketItems.push({ name: "", value: mods[mi] });
                                            }
                                        }
                                    }
                                }
                            }

                            if (socketItems.length < maxSockets) {
                                for (let i = curSockets; i < maxSockets; i++) {
                                    socketItems.push({ name: count++ + '. Empty.', value: "" });
                                }
                            }
                        }
                        break;
                    default: break;
                }

            }
        }

        const itemInfo = {
            picId,
            name,
            description,
            type,
            type2,
            val,
            speed,
            requirements,
            modifiers,
            uniModifiers,
            curSockets,
            maxSockets,
            socketItems
        };
        return itemInfo;
    }

    let localbuffs = [];

    function removeBuff(data) {
        let pos = -1;
        for (let i = 0; i < localbuffs.length; i++) {
            if (localbuffs[i].name === data) {
                pos = i;
                break;
            }
        }
        if (pos >= 0) {
            localbuffs.splice(pos, 1);
            setBuffs(localbuffs);

        }
        //502
        //  const newBuffs = buffs.filter(b => {return b.name !== data});
        //  setBuffs(newBuffs);
    }

    function updateBuffs(data) {
        const split = data.split(';');
        localbuffs = [];

        split.forEach(s => {
            if (s.length > 0) {
                const info = s.split('/');
                const picId = parseInt(info[0]);
                const name = info[1];
                const shortName = info[2];
                const description = info[3];
                const duration = parseInt(info[4]);
                const isDebuff = info[5] === 'true';
                localbuffs.push({ picId, name, shortName, description, duration, isDebuff });
            }

        });
        setBuffs([...localbuffs]);
    }

    function parseAbilityRequirements(data) {
        const requirements = [];
        const req = data.split('-');
        req.forEach(r => {
            if (r.includes(':')) {
                const info = r.split(':');
                if (info.length > 0) {
                    requirements.push({ name: info[0], val: info[1], meetRequirement: info[2] });
                }
            }
        })
        return requirements;
    }

    function parseAbilityModifiers(data) {
        const modifiers = [];
        const mod = data.split('¬');
        mod.forEach(m => {
            if (m.includes(':')) {
                const info = m.split(':');
                modifiers.push({ name: info[0], val: info[1] });
            }
        })
        return modifiers;
    }


    function updateStamina(data) {
        if (data.includes('/')) {
            const stamina = data.split('/');
            const type = stamina[0];
            const time = parseInt(stamina[1]);
            setStamina({ type: type, duration: time, casting: true });
        } else {
            const time = parseInt(data);
            setStamina({ type: 'stamina', duration: time, casting: false });
        }
    }


    function updateGold(data) {
        const gold = parseInt(data);
        setGold(gold);
    }

    function updateMap(data) {
        const mapData = data.split('#');
        const grid = [];

        for (let i = 0; i < mapData.length - 1; i++) {
            if (mapData[i].length > 1) {
                grid.push(mapData[i]);
            } else {
                grid.push('5,5,5');
            }
        }
        setMapGrid(grid);
    }

    function updateMapPosition(data) {
        const pos = data.split("/");
        setPosition([parseInt(pos[0]) + 1, parseInt(pos[1]) + 1]);
    }

    function updateHealth(data) {
        const val = data.split('-');
        const hp = { cur: val[0], max: val[1] };
        setHealth(hp);
    }

    function updateMana(data) {
        const val = data.split('-');
        const mp = { cur: val[0], max: val[1] };
        setMana(mp);
    }

    function updateExperience(data) {
        const val = data.split('/');
        const xp = { cur: val[0], max: val[1] };
        setExperience(xp);
    }

    function updatePlayerStats(data) {
        const stats = data.split(":");
        const level = parseInt(stats[0]);
        const str = parseInt(stats[1]);
        const mstr = parseInt(stats[2]);
        const agi = parseInt(stats[3]);
        const magi = parseInt(stats[4]);
        const tog = parseInt(stats[5]);
        const mtog = parseInt(stats[6]);
        const intel = parseInt(stats[7]);
        const mintel = parseInt(stats[8]);
        const wis = parseInt(stats[9]);
        const mwis = parseInt(stats[10]);
        const chr = parseInt(stats[11]);
        const mchr = parseInt(stats[12]);
        const armour = parseInt(stats[13]);
        const playerData = { level: level, str: str, mstr: mstr, agi: agi, magi: magi, tog: tog, mtog: mtog, intel: intel, mintel: mintel, wis: wis, mwis: mwis, chr: chr, mchr: mchr, armour: armour };
        setPlayerStats(playerData);
    }

    function updateRoomInfo(data) {
        const splitRoom = data.split(';');
        const area = splitRoom[0];
        const title = splitRoom[1];
        const exits = splitRoom[2];
        const desc = splitRoom[3];
        // const something1 = splitRoom[4];
        // const something2 = splitRoom[5];
        const roominfo = { area: area, title: title, exits: exits, desc: desc };
        setRoomInfo(roominfo);
    }

    function updateDisplay(data) {
        const parsed = parseText(data);
        setMessages((prev) => [...prev, parsed]);
    }

    async function login(username, password) {
        const pw = await hash(password);
        connect(username, pw);
    }

    async function register(username, password, email) {
        const pw = await hash(password);
        registerAccount(username, pw, email);
    }

    function parseText(message) {
        const temp = message.substring(2).split('{');
        return temp.map(formatText); // Use the formatText function directly
    }

    // let italic = false;
    let underline = false;
    let bold = false;
    let strikeThrough = false;
    let colour = "#ffffff";
    let counter = 0;
    function formatText(text) {
        if (text.length >= 2) {
            const type = text.substring(0, 2);

            let msg = "";

            switch (type) {
                case 'c}':
                    colour = "#" + text.substring(2, 8);
                    msg = text.substring(8, text.length);
                    break;
                case 'u}':
                    underline = !underline;
                    msg = text.substring(2, text.length);
                    break;
                case 'b}':
                    bold = !bold;
                    msg = text.substring(2, text.length);
                    break;
                case 's}':
                    strikeThrough = !strikeThrough;
                    msg = text.substring(2, text.length);
                    break;
                case 'h}':
                    break;
                default:
                    break;
            }
            counter++;
            return <span key={counter} style={{ color: colour, fontWeight: bold ? '800' : '500', textDecoration: underline ? 'underline' : '' }}>{msg}</span>;
        }
    }

    const sendMessage = (message) => {
        socket.send(message);
    }
    async function hash(string) {
        const utf8 = new TextEncoder().encode(string);
        const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
            .map((bytes) => bytes.toString(16).padStart(2, '0'))
            .join('');
        return hashHex;
    }

    const onClick = (e) => {
        socket.send(e);
    }

    if (loggedIn) {
        if (playing) {
            return (
                <AppState.Provider value={{ roomInfo, playerInfo, health, mana, experience, roomListMobs, roomListPlayers, stamina, gold, sendMessage, trainer, abilityInfo, statPoints, buffs }}>
                    <Main messages={messages} sendMessage={sendMessage} mapInfo={mapGrid} position={position} playerStats={playerStats} shopList={shopList} shopInfo={shopInfo} gold={gold} inventory={inventory} inventoryInfo={inventoryInfo} equipment={equipment} />
                </AppState.Provider>
            );
        }
        return <Account characterList={characterList} onClick={onClick} createInfo={createInfo} />
    } else {
        return <Login login={login} register={register} connecting={connecting} connected={connected} loggedIn={loggedIn} />
    }
}

export default Home;