import React from "react";
import './PlayerActions.css';


function PlayerActions({displayModal, sendMessage}) {

    const action = () => {
        sendMessage('600/trainstats');
        displayModal('trainstats');
    }

    const showStats = () => {
        sendMessage('600/stats');
    }

    const showAbilities = () => {
        sendMessage('600/skills');
    }

    const showInventory = () => {
        displayModal('inventory');
    }

    return (
        <div className="selector">
            <button className="action" onClick={action}>Train Stats</button>
            <button className="action" onClick={showInventory}>Inventory</button>
            <button className="action" onClick={showAbilities}>Abilities</button>
            <button className="action" onClick={showStats}>Show Stats</button>
        </div>
    )
}

export default PlayerActions;