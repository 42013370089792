import React, { useState } from "react";
import './ContextMenu.css';

function ContextMenu({ x, y, sendMessage, item, index }) {
    const [option, setOption] = useState(null);
    //tag 

    const setMenu = (e, menu) => {
        e.preventDefault();
        e.stopPropagation();
        setOption(menu);
    }

    return (
        <div className="context-menu" style={{ left: x + 'px', top: y + 'px' }}>
            {option === null && item &&
                <ul>
                    {item.type === 'Equipment' && <li onClick={()=> sendMessage('321iuse;' + index)}>Equip</li>}
                    {item.type === 'Potion' && <li  onClick={()=> sendMessage('321iuse;' + index)}>Use</li>}
                    {item.type === 'Container' && <li>Open</li>}
                    <li onClick={(e) => setMenu(e, 'auction')}>Auction</li>
                    {(item.uni === "true" || item.usedSockets > 0 || item.cap > 0) && <li onClick={(e) => setMenu(e, 'tag')}>Tag</li>}
                    <li onClick={(e) => setMenu(e, 'drop')}>Drop</li>
                </ul>
            }
            {option === 'drop' && <DropOption index={index} item={item} sendMessage={sendMessage} />}
            {option === 'auction' && <AuctionOption index={index} item={item} sendMessage={sendMessage} />}
            {option === 'tag' && <TagOption index={index} item={item} sendMessage={sendMessage} />}
        </div>
    )
}

function TagOption({ index, item, sendMessage }) {
    const [tag, setTag] = useState('');

    const tagItem = (e) => {
        sendMessage('600/tag ' + index + ' ' + tag);
    }

    return (
        <div className="menu-drop">
            <h3>Tag</h3>
            <div className="drop-wrapper">
                <label >Tag</label>
                <input type="text" className="auction-gold" value={tag} onChange={(e) => setTag(e.currentTarget.value)} onClick={(e) => e.stopPropagation()} onKeyDown={(e)=> e.stopPropagation()}></input>
            </div>
            <div>
                <button className="confirm-btn" onClick={(e)=> tagItem(e)}>Confirm</button>
                <button className="drop-btn">Cancel</button>
            </div>
            {/* <p className="auc">Will display a message to everyone. '/auc i' to view item stats</p> */}
        </div>
    )
}

function AuctionOption({ index, item, sendMessage }) {
    const [amount, setAmount] = useState(1);
    const [message, setMessage] = useState('');

    const auctionItem = (e) => {
        sendMessage('600/auc ' + index + ', ' + amount + ', ' + message)
    }

    return (
        <div className="menu-drop">
            <h3>Auction</h3>
            <div className="drop-wrapper">
                <label >Gold</label>
                <input type="text" placeholder="Gold" className="auction-gold" value={amount} onChange={(e) => setAmount(e.currentTarget.value)} onClick={(e) => e.stopPropagation()}></input>
                <label >Message</label>
                <input type="text" maxLength={22} placeholder="Message" className="auction-gold" value={message} onChange={(e) => setMessage(e.currentTarget.value)} onClick={(e) => e.stopPropagation()} onKeyDown={(e)=> e.stopPropagation()}></input>
            </div>
            <div>
                <button className="confirm-btn" onClick={(e)=> auctionItem(e)}>Confirm</button>
                <button className="drop-btn" >Cancel</button>
            </div>
            {/* <p className="auc">Will display a message to everyone. '/auc i' to view item stats</p> */}
        </div>
    )
}

function DropOption({ index, item, sendMessage }) {
    const [amount, setAmount] = useState(1);
    const increaseAmount = (e) => {
        e.stopPropagation();
        if (amount < item.qty) {
            setAmount(prev => prev + 1);
        }
    }

    const decreaseAmount = (e) => {
        e.stopPropagation();
        if (amount > 1) {
            setAmount(prev => prev - 1);
        }
    }

    const allAmount = (e) => {
        e.stopPropagation();
        setAmount(item.qty);
    }

    const dropItem = (e) => {
        if (item) {
            sendMessage('600/drop ' + amount + ' ' + index);
        }
    }



    return (
        <div className="menu-drop">
            <h3>Drop</h3>
            <div className="drop-wrapper">
                <button className="drop-amt" onClick={(e) => decreaseAmount(e)}>-</button>
                <input type="text" className="drop-input" value={amount} onChange={(e) => setAmount(e.currentTarget.value)} onClick={(e) => e.stopPropagation()}></input>
                <button className="drop-amt" onClick={(e) => increaseAmount(e)}>+</button>
                <button className="drop-amt" onClick={(e) => allAmount(e)}>All</button>
            </div>
            <div>
                <button className="confirm-btn" onClick={(e) => dropItem(e)}>Drop</button>
                <button className="drop-btn" >Cancel</button>
            </div>
        </div>
    )
}

export default ContextMenu;