import React, { useState } from "react";
import './TrainStat.css';
import StatIncrement from "./StatIncrement";

function TrainStat({ displayModal, playerStats, statPoints, sendMessage }) {
    const intitalValues = {
        strength: 0,
        agility: 0,
        toughness: 0,
        intelligence: 0,
        wisdom: 0,
        charisma: 0,
    };

    const [trainStats, setTrainStats] = useState(intitalValues);

    const increaseStat = (name) => {
        const totalTrainStats = Object.values(trainStats).reduce((a, b) => a + b, 0);

        if (totalTrainStats < statPoints) {
            setTrainStats(prevStats => ({ ...prevStats, [name.toLowerCase()]: prevStats[name.toLowerCase()] + 1 }));
        }
    }


    const decreaseStat = (name) => {
        if (trainStats[name.toLowerCase()] > 0) {
            setTrainStats(prevStats => ({ ...prevStats, [name.toLowerCase()]: prevStats[name.toLowerCase()] - 1 }));
        }
    }

    const reset = () => {
        setTrainStats(intitalValues);
    }

    const confirmStats = () => {

        sendMessage('604' 
        + trainStats.strength  + ";"
        + trainStats.agility  + ";"
        + trainStats.toughness  + ";"
        + trainStats.wisdom  + ";"
        + trainStats.intelligence  + ";"
        + trainStats.charisma  + ";");
        reset();
    }


    return (
        <div className="tmodal" style={{ display: "block" }}>
            <div className="tmodal-content">
                <div className="tmodal-header">
                    <span className="close" onClick={displayModal}>&times;</span>
                    <h2>Train Stats</h2>
                </div>
                <div className="stat-points">
                <p className="title">Stat Points (<span className="points">{statPoints - Object.values(trainStats).reduce((a, b) => a + b, 0)}</span>)</p>
                </div>
                <div className="tmodal-body">
                    <StatIncrement name={'Strength'} value={playerStats.str} increase={trainStats.strength} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                    <StatIncrement name={'Agility'} value={playerStats.agi} increase={trainStats.agility} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                    <StatIncrement name={'Toughness'} value={playerStats.tog} increase={trainStats.toughness} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                    <StatIncrement name={'Wisdom'} value={playerStats.wis} increase={trainStats.wisdom} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                    <StatIncrement name={'Intelligence'} value={playerStats.intel} increase={trainStats.intelligence} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                    <StatIncrement name={'Charisma'} value={playerStats.chr} increase={trainStats.charisma} increaseStat={increaseStat} decreaseStat={decreaseStat} />
                </div>
            
                <div className="tmodal-footer">
                    <button className="ts-close" onClick={displayModal}>Close</button>
                    <button className="ts-button"onClick={reset}>Reset</button>
                    <button className="ts-button" onClick={confirmStats}>Confirm</button>
                </div>

            </div>
        </div>
    );
}


export default TrainStat;