import React from "react";
import './listhealth.css';

function ListHealth({ hp }) {
    const per = hp;
    return (
        <div className="b-outer">
            <span className="hp">{hp}%</span>
            <div className="b-inner" style={{ width: per + '%' }} >
            </div>
        </div>
    );
}

export default ListHealth;