import React, { useState, useEffect } from "react";
import './Inventory.css';
import { Information, Requirements, Modifiers, SocketItem } from "../Shop/Shop";
import Equipment from "./Equipment";
import ContextMenu from "../ContextMenu/ContextMenu";


function Inventory({ displayModal, inventory, equipment, itemInfo, sendMessage }) {
    const [draggedItem, setDraggedItem] = useState(null);
    const [displayMenu, setDisplayMenu] = useState(null);
    const [selectedPos, setSelectedPos] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        const handleClick = (e) => {
            setDisplayMenu(null);
        }

        window.addEventListener('click', handleClick);

        return () => window.removeEventListener('click', handleClick);

    }, []);



    function getItemInfo(e, id, item) {
        setSelectedPos(id);
        setSelectedItem(item);
        if(displayMenu === null) {
            if (e.detail === 1) {
                sendMessage('321IINFO;' + id);
            } else {
                sendMessage('321iuse;' + id);
            }
        }
       
    }

    function getEquipInfo(e, id) {
        e.preventDefault();
        sendMessage('321EINFO;' + id);
    }

    function unequip(id) {
        sendMessage('321UNEQ;' + id);
    }

    const handleDragStart = (e, id, picId) => {
        e.dataTransfer.setData('text/plain', 'test');
        setDraggedItem(Number(id));
        const img = new Image(10, 10);
        img.src = require('../../Assets/gfx/' + picId + '.png');
        img.width = 10;
        img.style.width = 10;
        e.dataTransfer.setDragImage(img, 10, 10);
    }

    const handleDrop = (e, slot) => {
        e.preventDefault();
        if (draggedItem !== null) {
            sendMessage('321EQ;' + slot + " " + draggedItem);
            setDraggedItem(null);
        }
    }

    const moveItem = (e, index) => {
        e.preventDefault();
        if (draggedItem !== null) {
            sendMessage('321IMOV;' + draggedItem + ' ' + index);
            setDraggedItem(null);
        }
    }

    const displayContextMenu = (xPos, yPos, item, index) => {
        setDisplayMenu({ x: xPos, y: yPos });
    }

    return (
        <div className="modal" style={{ display: "block" }}>
            <div className="modal-content" style={{ width: '900px' }}>
                <div className="tmodal-header">
                    <span className="close" onClick={displayModal}>&times;</span>
                    <h2>Inventory</h2>
                </div>
                <div className="modal-body" style={{ width: '100%', padding: '2px 5px' }}>
                    <div className="inv-panel">
                        <div>
                            <div className="info-header"><p style={{ color: (inventory.invCount > inventory.invMax) ? 'red' : 'white' }}>Items ({inventory.invCount} / {inventory.invMax})</p></div>
                            <InventoryList itemList={inventory.itemList} getItemInfo={getItemInfo} drag={handleDragStart} moveItem={moveItem} rightClick={displayContextMenu} selected={selectedPos} />
                        </div>
                        <div>
                            <Equipment equipment={equipment} getEquipInfo={getEquipInfo} unequip={unequip} handleDrop={handleDrop} />
                            {itemInfo && <InformationPanel itemInfo={itemInfo} />}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="ts-close" onClick={displayModal}>Close</button>
                </div>
            </div>
            {displayMenu && <ContextMenu x={displayMenu.x} y={displayMenu.y} sendMessage={sendMessage} item={selectedItem} index={selectedPos}/>}
        </div>
    );
}

function InformationPanel({ itemInfo }) {
    return (
        <div style={{ marginTop: '5px' }}>
            <div className="info-header">
                <p>Information</p>
            </div>
            <div className="info-desc" style={{ maxHeight: '280px', overflowY: 'auto' }}>
                <Information shopInfo={itemInfo} />
                <p className="inv-desc" style={{ border: '1px solid #333333', height: '75px', margin: '4px 0px', paddingLeft: '4px' }}>{itemInfo.description}</p>
                <div className="stats">
                    <Requirements reqs={itemInfo.requirements} />
                    <Modifiers mods={itemInfo.modifiers} uniMods={itemInfo.uniModifiers} />
                </div>
                {itemInfo.maxSockets > 0 && <SocketItem shopInfo={itemInfo} />}
            </div>
        </div>
    );

}

function InventoryList({ itemList, getItemInfo, drag, moveItem, rightClick, selected }) {

    return (
        <div className="shop-list" style={{ height: '650px' }}>
            <ul>
                {itemList.map((e, i) => <Item key={i} item={e} index={i} isSelected={(i === selected)} getItemInfo={getItemInfo} drag={drag} moveItem={moveItem} rightClick={rightClick} />)}
            </ul>
        </div>
    )
}


function Item({ item, getItemInfo, index, isSelected, drag, moveItem, rightClick }) {
    const [isHovered, setIsHovered] = useState(false);
    
    const rightClickMenu = (e,index,item) => {
        e.preventDefault();
        e.stopPropagation();
        const x = e.clientX;
        const y = e.clientY;
        rightClick(x, y, item, index);
    }

    return (
        <li style={{ borderColor: isSelected ? 'white' : '' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {(isHovered) &&
                <div className="more-options" onClick={(e) => {
                    getItemInfo(e, index,item);
                    rightClickMenu(e,index);
                }}>
                    <span className="material-symbols-outlined">
                        more_horiz
                    </span>
                </div>
            }

            <div className="item"
                draggable

                onClick={(e) => getItemInfo(e, index)}
                onDragStart={(e) => { drag(e, index, item.picId) }}
                onDrop={(e) => moveItem(e, index)}
                onDragOver={(e) => e.preventDefault()}
                onContextMenu={(e) => {
                    getItemInfo(e, index,item);
                    rightClickMenu(e,index,item);
                }}
            >
                {/* <div className="item"> */}
                <img src={require('../../Assets/gfx/' + item.picId + '.png')} alt=""/>
                <div className="item-info">
                    <span className="item-name" style={{ color: item.colour }}>
                        {index + 1}. {item.name} {item.qty > 1 && <> ({item.qty})</>} {item.maxSockets > 0 && <> [{item.usedSockets}/{item.maxSockets}]</>}
                        {item.cap > 0 && <> [{item.conItems}/{item.cap}]</>}
                    </span>
                    <span className="tag">

                    </span>
                </div>
            </div>
        </li>
    );
}

export default Inventory;