import React, { useEffect, useState } from "react";
import './HotkeyEdit.css';

function HotkeyEdit({ displayModal, actions, setActions }) {
    const keys = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9'];
    const [inputValues, setInputValues] = useState({});
    const [actionSet, setActionSet] = useState(0);

    function handleInputChange(key, name, cmd) {
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [key]: { name, cmd },
        }));
    }

    function handleSaveClick() {
        const updatedActions = [...actions];
        keys.forEach((key) => {
            if (inputValues[key]) {
                const { name, cmd } = inputValues[key];
                const functionIndex = updatedActions[actionSet].findIndex(
                    (action) => action.key === key
                );
                if (functionIndex !== -1) {
                    updatedActions[actionSet][functionIndex] = {
                        ...updatedActions[actionSet][functionIndex],
                        name,
                        cmd,
                    };
                }
            }
        });
        setActions(updatedActions);
    }

    function changeSet(val) {
        if(val >= 0 && val <= actions.length-1){
            setActionSet(val);
            console.log(val);
        }
    }

    return (
        <div className="modal" style={{ display: "block" }}>
            <div className="modal-content" style={{ width: '560px' }}>
                <div className="tmodal-header">
                    <span className="close" onClick={displayModal}>&times;</span>
                    <h2>Edit Hotkey</h2>
                </div>
                <div className="modal-body" style={{ width: '100%', padding: '2px 5px' }}>
                    <HotkeyOptions changeSet={changeSet}/>
                    <div className="edit-wrapper">
                        <HotKeyHeading />
                        {keys.map((key) => (
                            <HotKeyInput
                                key={key}
                                k={key}
                                action={actions[actionSet].find((action) => action.key === key)}
                                onUpdateAction={handleInputChange}
                            />
                        ))}
                    </div>
                    <div >
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="ts-close" onClick={displayModal}>Close</button>
                    <button className="ts-button" onClick={handleSaveClick}>Save</button>
                </div>
            </div>
        </div>
    );

}

function HotkeyOptions({changeSet}) {
    return (
        <div className="hotkey-sets">
            <button>Mouse</button>
            <button onClick={()=> changeSet(0)}>Set 1</button>
            <button onClick={()=> changeSet(1)}>Set 2</button>
            <button onClick={()=> changeSet(2)}>Set 3</button>
            <button onClick={()=> changeSet(3)}>Set 4</button>
        </div>
    )
}

function HotKeyHeading() {
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '35px 1fr 1fr', fontWeight: '600', paddingLeft: '2px', marginBottom: '2px' }}>
            <span>Key</span>
            <span>Name</span>
            <span>Command</span>
        </div>
    )
}

function HotKeyInput({ k, action, onUpdateAction }) {
    const [hotkeyName, setHotkeyName] = useState(action?.name);
    const [hotkeyCmd, setHotkeyCmd] = useState(action?.cmd);

    useEffect(() => {
        setHotkeyName(action?.name);
        setHotkeyCmd(action?.cmd);
    }, [action]);

    const handleInputChange = () => {
        onUpdateAction(k, hotkeyName, hotkeyCmd);
    };

    return (
        <>
            <div style={{ display: 'grid', gridTemplateColumns: '35px 1fr 1fr', gap: '4px' }}>
                <span>{k}</span>

                <input
                    className="hotkey-input"
                    type="text"
                    onChange={(e) => setHotkeyName(e.currentTarget.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                    value={hotkeyName}
                    onBlur={handleInputChange} // Call the callback on blur
                />
                <input
                    className="hotkey-input"
                    type="text"
                    onChange={(e) => setHotkeyCmd(e.currentTarget.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                    value={hotkeyCmd}
                    onBlur={handleInputChange} // Call the callback on blur
                />
            </div>
        </>

    );
}

function HotKeyInpu2t({ k, action }) {
    const [hotkeyName, setHotkeyName] = useState(action?.name);
    const [hotkeyCmd, setHotkeyCmd] = useState(action?.cmd);
    return (
        <div style={{ display: 'grid', gridTemplateColumns: '35px 1fr 1fr', gap: '4px' }}>
            <span>{k}</span>
            <input className="hotkey-input" type="text" onChange={(e) => setHotkeyName(e.currentTarget.value)} onKeyDown={(e) => e.stopPropagation()} value={hotkeyName} />
            <input className="hotkey-input" type="text" onChange={(e) => setHotkeyCmd(e.currentTarget.value)} onKeyDown={(e) => e.stopPropagation()} value={hotkeyCmd} />
        </div>
    )
}

export default HotkeyEdit;