import React from 'react';
import './userlist.css';
import ListHealth from './Components/ListHealth';

function UserListElement({ pos, details, sendMessage, displayModal }) {
    //todo check asset/gfx exists or will crash...

    const train = (action) => {
        sendMessage("316" + details.id + ';' + action);
        if (action === 'learn') {
            displayModal('trainer');
        }
        if (action === 'shop') {
            displayModal('shop');
        }

    }

    const border = () => {
        switch (details.attacking) {
            case 0: return " default-border";
            case 1: return " aggro-border blinking";
            case 2: return " aggro-party-border";
            default:
                break;
        }
    }

    const status = (status) => {
        switch (status) {
            case 0: return '#A9AAB0'
            case 1: return '#4DBA09';
            case 2: return '#BD0404';
            default:
                return '#BBDDEE';
        }
    }

    const selected = () => {
        sendMessage('345' + (details.type === 'mob') + '/' + details.id)
    }

    const classNames = 'userlist-element' + border() + (details.selected ? ' user-selected ' : ' user-default');



    return (
        <>
            <div className={classNames} onClick={e => selected()}>
                <div className='portrait'>
                    <img src={require('./Assets/gfx/' + details.picId + '.png')} alt=''/>
                    <p>Lv. {details.level}</p>
                </div>
                <div className={'userlist-details'}>
                    <p style={{ color: status(details.friend) }}>{pos}. {details.type === 'player' && ' (Player)'} {details.name}</p>
                    <div className='mid'>
                        {details.actions && details.actions.length > 0 && details.actions.map(action => (<button key={action} onClick={() => train(action)}>{action.charAt(0).toUpperCase() + action.slice(1)}</button>))}
                    </div>
                    <div className='user-hp'>
                        {<ListHealth hp={details.hp} />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserListElement;