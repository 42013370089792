import React from "react";
import './ChatSelector.css';

function ChatSelector({setChannel}) {

    return (
        <>
            <select className="channel-selector" onChange={(e)=>setChannel(parseInt(e.currentTarget.value))}>
                <option value={0}>Say</option>
                <option value={1}>Global</option>
                <option value={2}>Party</option>
                <option value={3}>Clan</option>
            </select>
        </>
    );
}

export default ChatSelector;